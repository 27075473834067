import axiosConfig from "../axiosConfig";
import authHeader from './auth-header';

class AccountTransactionsService {

  getAccountTransactions(id, startDate, endDate) {
    return axiosConfig.get('account/' + id, { headers: authHeader(), params: {start: startDate, end: endDate}});
  };

  postAccountTransactions(id, rows) {
    return axiosConfig.post('account/' + id, rows, { headers: authHeader() });
  };
}

export default new AccountTransactionsService();