import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CategoriesService from "../services/category-service";
import AccountTransactionsService from "../services/account-transactions-service";

const initialState = {
  categories: [],
  status: 'idle',
  error: null
}

export const fetchCategories = createAsyncThunk('categories', async () => {
  const response = await CategoriesService.getCategories();
  return response.data
});

export const addTransfer = createAsyncThunk(
  'transfer',
  // The payload creator receives the partial `{transferAccountid, content}` object
  async initialTransfer => {
    const response = await AccountTransactionsService.postAccountTransactions(initialTransfer.transferAccountId, initialTransfer.rows)
    // The response includes the complete post object, including unique ID
    return response.data
  }
)

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
      categoriesAdded: {
        reducer(state, action) {
          state.categories.push(action.payload)
        },
        prepare(title, content, userId) {
          // omit prepare logic
        }
      },
      categoriesUpdated(state, action) {
        const { id, name, content } = action.payload
        const existingCategory = state.categories.find(category => category.id === id)
        if (existingCategory) {
          existingCategory.name = name
          existingCategory.content = content
        }
      }
    },
    extraReducers: {
      [fetchCategories.pending]: (state, action) => {
        state.status = 'loading'
      },
      [fetchCategories.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched categories to the array
        state.categories = action.payload
      },
      [fetchCategories.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
      [addTransfer.pending]: (state, action) => {
        state.status = 'loading'
      },
      [addTransfer.fulfilled]: (state, action) => {
        state.status = 'transferred'
      },
      [addTransfer.rejected]: (state, action) => {
        state.error = action.error.message
      }
    }
  })
  
  export const { categoryAdded, categoryUpdated } = categoriesSlice.actions
  
  export default categoriesSlice.reducer
  
  export const selectAllCategories = state => state.categories.categories
  
  export const selectCategoryById = (state, categoryId) =>
    state.categories.categories.find(category => category.id === categoryId)