import {
  Announcement,
  Banner,
  BodyText,
  Button,
  ButtonGroup,
  Card,
  Form,
  Layout,
  Modal,
  Page,
  Stack,
} from "@servicetitan/design-system";
import { ModalSizes } from "@servicetitan/design-system/dist/components/Modal/components";
import { observer } from "mobx-react";
import { LoginStore } from "../stores/login.store";

let store = new LoginStore();

export const LoginComponent = observer(function LoginComponent() {
  return (
    <Page maxWidth="narrow">
      <Layout type="island" className="m-x-5">
        <Layout.Section className="m-x-5">
          <Card elevation="raised" light className="m-x-5">
            {store.showForgotPasswordAnnouncemnt && (
              <Announcement
                title={store.annoucementMessage}
                onClose={() => store.setShowForgotEmailAnnoucement(false)}
                status={store.annoucementSuccess ? "info" : "critical"}
              />
            )}
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card m-t-5"
            />
            <Stack direction="column" alignContent="center" spacing={2}>
              {store.showIncorrectUsernamePasswordBanner && (
                <Banner
                  status="critical"
                  title="Incorrect username or password."
                />
              )}
              <Stack.Item>
                <Form.Input
                  label="Username"
                  value={store.username}
                  onChange={store.setUsername}
                  placeholder="Username"
                  required
                  name="username"
                  type="username"
                  id="username"
                />
              </Stack.Item>
              <Stack.Item>
                <Form.Input
                  label="Password"
                  value={store.password}
                  onChange={store.setPassword}
                  placeholder="Password"
                  required
                  type="password"
                  name="password"
                  id="password"
                />
              </Stack.Item>
              <Stack.Item>
                <ButtonGroup>
                  <Button onClick={store.login} primary>
                    Login
                  </Button>
                  <Button onClick={() => store.setShowForgotEmail(true)}>
                    Forgot Password
                  </Button>
                </ButtonGroup>
              </Stack.Item>
            </Stack>
          </Card>
        </Layout.Section>
      </Layout>
      <Modal
        size={ModalSizes.S}
        open={store.showForgotEmail}
        footer={
          <ButtonGroup>
            <Button onClick={() => store.setShowForgotEmail(false)}>
              Cancel
            </Button>
            <Button primary onClick={store.sendForgotPasswordEmail}>
              Send Reset Email
            </Button>
          </ButtonGroup>
        }
        footerAlign="space-between"
      >
        <BodyText>
          Please enter your username to receive an email with a link that takes
          you to a form where you can reset your password.
        </BodyText>
        <Form.Input
          label="Username"
          value={store.forgotPasswordUsername}
          onChange={store.setForgotPasswordUsername}
          placeholder="Username"
          required
          type="username"
          name="username"
        />
      </Modal>
    </Page>
  );
});
