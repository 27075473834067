import {
  BackLink,
  BodyText,
  Card,
  Form,
  Headline,
  Stack,
} from "@servicetitan/design-system";
import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { TransactionStore } from "../stores/transaction.store";

let store = new TransactionStore();

const accountString: string = "account";
const categoryString: string = "category";
const transactionString: string = "transaction"

export const TransactionComponent = observer(function TransactionComponent() {
  const transactionId = React.useState(
    Number(location.pathname.slice(location.pathname.lastIndexOf("/") + 1))
  );
  const accountId = isNaN(Number(
    location.pathname.slice(
      location.pathname.lastIndexOf(accountString) + accountString.length + 1,
      location.pathname.lastIndexOf(transactionString) - 1
    )
  )) ? 0 : Number(
    location.pathname.slice(
      location.pathname.lastIndexOf(accountString) + accountString.length + 1,
      location.pathname.lastIndexOf(transactionString) - 1
    )
  ) 

  const categoryId = isNaN(Number(
    location.pathname.slice(
      location.pathname.lastIndexOf(categoryString) + categoryString.length + 1,
      location.pathname.lastIndexOf(transactionString) - 1
    )
  )) ? 0 : Number(
    location.pathname.slice(
      location.pathname.lastIndexOf(categoryString) + categoryString.length + 1,
      location.pathname.lastIndexOf(transactionString) - 1
    )
  ) 

  store.previousAccountId = accountId
  store.previousCategoryId = categoryId;
  useEffect(() => {
    store.getTransaction(Number(transactionId[0]), accountId, categoryId);
  }, [store]);

  return (
    <Fragment>
      <Card>
        <br />
        <Stack direction="column-reverse" spacing="3">
          <Stack.Item>
            <Headline>Transaction</Headline>
          </Stack.Item>
          <Stack.Item>
            {store.previousAccountId > 0 && (
              <BackLink pageLabel="Transactions" href={"/accounts/" + store.previousAccountId} />
            )}
            {store.previousCategoryId > 0 && (
              <BackLink pageLabel="Transactions" href={"/categories/" + store.previousCategoryId} />
            )}
            
          </Stack.Item>
        </Stack>

        {transactionId[0] == 0 && (
          <BodyText subdued> Create your new transaction. </BodyText>
        )}
        {transactionId[0] > 0 && (
          <BodyText subdued> Edit your existing transaction. </BodyText>
        )}
        <Form className="s-t-3">
          <Form.Group widths="equal">
            <Form.AnvilSelect
              value={store.newTransactionAccountId}
              options={store.accountsDropdown}
              label="Account"
              trigger={{ placeholder: "Select an account" }}
              required
              onChange={(data) => store.setNewTransactionAccountId(data)}
            />
            <Form.AnvilSelect
              value={store.newTransactionCategoryId}
              options={store.categoriesDropdown}
              label="Category"
              trigger={{ placeholder: "Select a category" }}
              required
              onChange={(data) => store.setNewTransactionCategoryId(data)}
            />
          </Form.Group>
          <Form.Group widths="2">
          <Form.Input
              label="Amount"
              value={store.newTransactionAmount}
              onChange={store.setNewTransactionAmount}
              placeholder="Amount"
              required emptyValue={0}              
            />
            <Form.Input
              label="Message"
              value={store.newTransactionMessage}
              onChange={store.setNewTransactionMessage}
              placeholder="Message"
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.AnvilSelect
              value={store.newTransactionTransferCategoryId}
              options={store.categoriesDropdown}
              label="Transfer Category"
              trigger={{ placeholder: "Select an transfer Category" }}
              onChange={(data) =>
                store.setNewTransactionTransferCategoryId(data)
              }
              description="For category transfers, the category transferred with."
            />
            <Form.DatePicker
              label="Transaction Date"
              value={store.newTransactionDate}
              onChange={(v) => store.setTransactionDate(v)}
              description="Date of Transaction"
            />
             <Form.DatePicker
              label="Budgeted Date"
              value={store.newBudgetedDate}
              onChange={(v) => store.setBudgetedDateDate(v)}
              description="Date budgeted in Lint."
            />
          </Form.Group>
          <Form.Group>
            <Form.Button onClick={store.save} primary>
              Save Transaction
            </Form.Button>
          </Form.Group>
        </Form>
      </Card>
    </Fragment>
  );
});
