import {
  BodyText,
  Card,
  Headline,
} from "@servicetitan/design-system/dist/components";
import { Fragment } from "react";

export const PrivacyComponent = () => {
  return (
    <Fragment>
      <Headline>Privacy</Headline>
      <Headline size="small">Effective Date: May 31, 2024</Headline>
      <BodyText class="p-5-y">
        <br />
        Lint Finance, Inc., a Washington LLC (“Lint”, “we”, or “us”), recognizes
        the importance of your privacy. We want you to feel confident using our
        services, and this privacy policy (“Privacy Policy” or “Policy”) is to
        help you understand and describe how we collect, use, and disclose your
        information.
        <br />
        <br />
        This Policy describes the following:
        <li>What information we collect and how it is collected;</li>
        <li>How we use the information;</li>
        <li>With whom we may share information;</li>
        <li>Legal basis for processing the information;</li>
        <li>Your rights and choices;</li>
        <li>Security and storage of the information;</li>
        <li>Third-party websites;</li>
        <li>Users outside the United States;</li>
        <li>California and Nevada Compliance;</li>
        <li>Changes to the Policy; and</li>
        <li>Contacting Lint.</li>
        <br />
        “Site,” “App,” and “Services” all have the meanings given to them in our
        “Terms of Service,” which can be found here
        lint-dev.com/terms-of-service.Anyone using the App, Site or one or more
        of the Services are referred to herein as “Users.” Users must have an
        Lint account. Throughout the Privacy Policy, “you” and “your” refer to
        Users and potential Users. Any terms defined in the Terms of Service and
        not otherwise defined in this Privacy Policy are hereby adopted by
        reference in this Privacy Policy.
      </BodyText>
      <Headline size="small">Information We Collect</Headline>
      <Card class="p-b-2">
        The information we collect depends on how you use the App, Site and
        Services, your location, and applicable laws.
        <br />
        <br />
        We may collect certain information about you that specifically
        identifies you or, when combined with other information we have, can be
        used to identify you, including, but not limited to, your name,
        telephone number, email address, and physical address, and we may also
        collect payment information, including your payment card number and
        associated billing information, and other profile information you upload
        to your account (collectively, “Personal Information”). We may also
        combine the information we have collected about you with information
        from publicly available sources. The Services are not intended for
        children under the age of thirteen (13). If you are between the ages of
        thirteen (13) years old and eighteen (18) years old, you are not
        permitted to use our Sitexw or our Service unless you have obtained
        consent from your parent or legal guardian. We do not knowingly collect,
        maintain or use personal information (as defined by the Children’s
        Online Privacy Protection Act) from children under the age of thirteen
        (13), and should we discover we were provided with such information, we
        will promptly delete such Personal Information from our systems. If you
        are a parent or guardian and you are aware that your child under the age
        of thirteen (13) has provided us with Personal Information, please
        contact us at alamus@umich.edu so that we can take all necessary action.
        <br />
        <br />
        Broadly speaking, the Personal Information we collect falls into the
        following categories:
        <br />
        1. Information you provide us:
        <li>
          In your communications with us, and through your use of our Site or
          Service, you or your organization may provide us with Personal
          Information voluntarily. This includes, but is not limited to:
        </li>
        <li>
          Information you provide when you create an account, including without
          limitation, contact information, log in credentials, and other profile
          information and preferences that you upload to your account (such as
          your name, email address, and phone number);
        </li>
        <li>
          Information you provide when you interact with the Services, including
          without limitation, financial information such as your account
          balance, descriptions of financial transactions, investment interests,
          budget names and amount, information about other users, and
          descriptions regarding the foregoing;
        </li>
        <li>
          Information that is uploaded during troubleshooting and support
          (including the content of your chats and other communications with us)
          and other information that we obtain when you contact us with
          questions or comments;
        </li>
        <li>
          Payment information: we use third party payment processors that
          collect and store your payment information, including your billing
          address and credit card information. We may collect and store payment
          related information (such as the payment amount, date, and card type);
          and
        </li>
        <li>
          Information you provide when you choose to integrate the Services with
          other apps or functionalities on your mobile device (such as your
          photos or contacts), including information that may be contained in
          those other apps/functionalities.
        </li>
        <li>
          For example, when you connect your registered account with us to
          financial institutions and other third-party data sources (“Financial
          Data Sources”) or you otherwise provide us with access to information
          from those Financial Data Sources, we collect certain information
          about you. In order to sync information from these Financial Data
          Sources, we will collect certain financial information, including your
          account balance, transactions, and holdings. We may also collect
          certain credential information necessary to facilitate an integration
          with a Financial Data Source that you have chosen to make. We may use
          third-party data sources to assist in collecting and syncing your
          information, including financial information, from Financial Data
          Sources, and by connecting your account to a Financial Data Source,
          you agree to all applicable third-party sources’ terms and conditions
          and privacy policies.
        </li>
        <br />
        2. Information we collect automatically:
        <br />
        We and our Service Providers (as defined below) use Cookies and other
        technologies to receive and store certain information, which may or may
        not be Personal Information. You may opt-out of relevant cookies using
        opt-out features on their respective websites. Based on your opt out
        preferences, we may automatically collect or receive information about
        you whenever you access the App, Site or Services. In some countries,
        this information is considered Personal Information under applicable
        data protection laws. Please be aware that if you disable or reject
        cookies, some features of the Services may not work properly.
        <br />
        This information may include:
        <li>
          Device information: We collect information about the device and
          applications you use to access the Site or Service, including your IP
          address, browser, and operating system.
        </li>
        <li>
          Log data: Our systems keep log data that includes information each
          time our App, Site or Services were accessed (such as time and
          originating IP address).
        </li>
        <li>
          Usage data: We collect data about your usage whenever you interact
          with our App, Site or Services. In addition, we use third-party
          Service Providers to collect certain analytics including information
          about the activities of visitors to our App and Site.
        </li>
        <li>
          Communications information: We collect information regarding
          communications (including notifications) sent using our App, Site or
          Services, or by us (such as delivery status of emails and replies to
          text notifications).
        </li>
        <br />
        3. Information we collect from other sources:
        <br />
        We may get information about you from third-parties, such as social
        media platforms, other users of the Services, payment service providers,
        analytics service providers, and security service providers.
        Furthermore, if you access the Services through a third-party connection
        or log-in (e.g., a social media account), you may allow us to have
        access to and store certain information from such third-party services,
        depending on your settings on that service.
      </Card>
      <Headline size="small">How We Use Your Information</Headline>
      <Card class="p-b-2">
        We may use information we collect, including Personal Information, to do
        the following:
        <li>
          Provide the Lint Services: In order to provide the best Services we
          can, we will use the information you provide. We may use your
          information to: manage your account, respond to your inquiries, and
          provide customer service and support, among other things. We use the
          payment information you provide to charge you for any paid
          subscriptions, alert you of charges, present you with billing history
          and functionality, and perform internal financial processes.
        </li>
        <li>
          Improve our products: In order to improve on and develop our products
          and services, we analyze your use of, and interest in, our App, Site
          and Services, and in some cases services and content offered by
          others.
        </li>
        <li>
          Service related communications. We may send you service and
          administrative emails. These messages are considered essential
          components of the Services and you may not opt out of these messages.
        </li>
        <li>
          Promotional. Unless you opt out, we may send you emails about Lint
          products and news, or other topics we think are relevant. You may opt
          out of receiving these communications at any time by following the
          unsubscribe instructions provided in the promotional email you receive
          or by emailing us directly at alamus@umich.edu with “OPT-OUT”,
          “UNSUBSCRIBE”, “STOP”, or “REMOVE” in the subject line. Removing your
          name from the email list may take a reasonable amount of time. Please
          note that, regardless of your request, we may still use and share
          certain information as permitted by this Privacy Policy or as required
          by applicable law.
        </li>
        <li>
          Responding to your requests. We will use your information to respond
          to your questions or comments.
        </li>
        <li>
          Administrative. We may contact you about changes in Services and
          offerings. We may also contact you with notices about Services and
          offerings, including changes to this Policy, and security and fraud
          notifications.
        </li>
        <li>
          Research and analytics. Subject to your opt-out preference, we may
          contact you as part of research or data analytics projects to aid in
          our legitimate business interests in improving our services and
          products.
        </li>
        <li>
          Protecting Rights and Interests: We may use your information to
          protect the rights and interests of Lint and our users, and to enforce
          our Terms of Use and this Policy.
        </li>
        <li>
          Legal Compliance: We may use your information in complying with
          applicable legal and regulatory obligations. This includes complying
          with requests from law enforcement and other governmental authorities,
          and in meeting demands stemming from legal proceedings.
        </li>
        <li>
          Settle a charge dispute: In the event of a payment dispute, we may
          share account information with our payment processors or your bank to
          verify the legitimacy of a charge.
        </li>
        <li>
          Combined Information: We may combine the information that we collect
          through the Services with information that we receive from other
          sources and use such combined information in accordance with this
          Privacy Policy.
        </li>
        <li>
          Aggregate/De-identified Information: We may aggregate and/or
          de-identify any information collected through the Services so that
          such information can no longer be linked to you or your device
          (“Aggregate/De-Identified Information”). We may use
          Aggregate/De-Identified Information for any purpose, including without
          limitation for research and marketing purposes, and may also share
          such data with any third parties in our discretion.
        </li>
        <li>
          Other: In the course of managing our business or performing business
          functions, we may use your information in a way described to you at
          the time of collection, and subject to your consent.
        </li>
        <br />
        <br />
        With Whom We May Share Your Information We do not sell your Personal
        Information. We do not disclose your Personal Information in exchange
        for money. We do not share your Personal Information except as indicated
        within this Policy.
        <br />
        <br />
        We may share your Personal Information and other information in the
        following ways:
        <li>
          With third-party Service Providers, agents, contractors, or government
          entities. In order to provide a valuable service, we may work with
          companies, agents, or contractors (“Service Providers”). We may engage
          Service Providers to process payments; monitor and develop Lint
          services; develop or maintain communications, infrastructure, or
          information technology services; provide customer service; collect
          debt; and analyze or enhance data. Service Providers may have access
          to your information as part of their duties. Service Providers may
          also collect information, which may be Personal Information, on our
          behalf. We may share information, which may be Personal Information,
          with Service Providers and government entities for the following
          reasons: to address legal, security and safety concerns; to enforce
          policies and contracts; to address security breaches; and to assist in
          investigations. We may also share information with the Financial Data
          Sources to help establish or maintain an integration you have chosen
          to make.
        </li>
        <li>
          We may share your data if we believe it is reasonably necessary for
          any of the following reasons: to comply with a law, regulation, legal,
          or governmental request, including any subpoena, court order, warrant,
          or other legal process; to enforce applicable Terms of Service or this
          Policy, including investigation of potential violations; to protect
          the safety, rights, or property of Lint, its Users, or the public; to
          detect, prevent, or otherwise address security or technical issues; to
          detect, prevent, or otherwise address illegal, or suspected illegal,
          activities, including fraud; or as evidence in litigation.
        </li>
        <li>
          Other Users. To the extent that you post content in any
          publicly-accessible parts of the Services or choose to make certain
          content or account information viewable by other users or the public,
          such content and information can be read, collected, and used by
          others with permission to view it.
        </li>
        <li>
          Affiliates. For research, marketing, and other purposes, we may
          disclose information to affiliates and subsidiaries in a manner
          consistent with this Policy.
        </li>
        <li>
          Your company. If you are using Lint as a member of a company or using
          a company email address, we may share information about your account
          with an authorized agent of your company upon request.
        </li>
        <li>
          Mergers and Sale Transactions. We may share your information,
          including Personal Information, in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of our business or assets, whether as a going concern
          or as part of bankruptcy, liquidation, or similar proceeding.
        </li>
      </Card>
      <Headline size="small">Information Shared with Other Users</Headline>
      <Card class="p-b-2">
        If you share your information with other Users, we take no
        responsibility for the way they use your information, and you provide
        such information at your own risk. If you feel your information is being
        misused, please contact us immediately and we will review this and take
        action as we deem appropriate. You can also contact us to ask to remove
        your information from our systems as defined in this Policy.
      </Card>
      <Headline size="small">Your Rights and Choices</Headline>
      <Card class="p-b-2">
        <li>
          Account. You can keep your Personal Information accurate and up to
          date by logging in and changing it. You may also contact us to request
          information about your Personal Information we have collected and to
          request corrections to it. You may also request that we delete your
          Personal Information or your entire account. We will try to honor your
          requests to the best of our ability, subject to any legal or
          contractual obligations. If you would like to make any of the
          aforementioned requests, please contact us at alamus@umich.edu. If you
          request for us to delete your account or Personal Information, we will
          only retain information as necessary to comply with legal obligations,
          resolve disputes, and enforce agreements.
        </li>
        <li>
          E-mail. You may opt out of promotional emails from us by following the
          opt-out link in any promotional email we send you. Please note that it
          may take up to ten (10) days for changes to take effect. If you opt
          out of promotional emails, we may continue to send service-related
          emails, which you may not opt-out from. If you do not wish to receive
          service-related emails, please delete your account.
        </li>
        <li>
          Cookies. You may disable Cookies via your browser settings or via
          limited opt-out functionality in Lint. Please refer to your browser’s
          help function for instructions about how to change Cookies settings.
          Please keep in mind that Lint uses Cookies to enable authentication
          and other features, so you may need Cookies in order to use the Site
          or Service.
        </li>
        <li>
          Third Party Analytics Services. Some of the third-party analytics
          services we use provide the ability to opt-out. You acknowledge that
          these third-party services are responsible for their own websites,
          services, and use of your Personal Information, and we recommend
          checking the website of each third-party service frequently.
        </li>
        <li>
          Additional Rights. Subject to local law, you may have additional
          rights under the laws of your jurisdiction regarding your Personal
          Information, such as the right to contact your local data protection
          authority for assistance.
        </li>
      </Card>
      <Headline size="small">Security and Storage of Information</Headline>
      <Card class="p-b-2">
        Lint takes the security of your Personal Information very seriously. We
        work hard to protect the Personal Information that you provide from
        loss, misuse, and unauthorized access, or disclosure. Lint uses Windows
        Information Protection (WIP); however, given the nature of
        communications and information processing technology, there is no
        guarantee that Personal Information will be absolutely safe from access,
        alteration, or destruction by a breach of any of our physical,
        technical, and managerial safeguards.
        <br />
        <br />
        You should take steps to protect against unauthorized access to your
        device and account by, among other things, choosing a unique and complex
        password that nobody else knows or can easily guess and keeping your
        log-in and password private. We are not responsible for any lost,
        stolen, or compromised passwords or for any activity on your account via
        unauthorized password activity.
        <br />
        <br />
        We retain the Personal Information we collect for so long as is
        reasonably necessary to fulfill the purposes for which the data was
        collected, to perform our contractual and legal obligations, and for any
        applicable statute of limitations periods for the purposes of bringing
        and defending claims.
      </Card>
      <Headline size="small">Third-Party Links</Headline>
      <Card class="p-b-2">
        Our Site may contain links to third-party websites and applications.
        Subject to your opt-out preferences (see Your Rights and Choices), we
        may also use third-party advertisers, ad networks, and other
        advertising, marketing, and promotional companies, to serve
        advertisements on our Site. Any access to and use of such linked
        websites and applications is not governed by this Policy but instead is
        governed by the privacy policies of those third parties. We do not
        endorse these parties, their content, or any products and services they
        offer. We are not responsible for the information practices of such
        third-party websites or applications, and should you choose to link to
        them through our Site you do so at your own risk.
      </Card>
      <Headline size="small">Users Outside the USA</Headline>
      <Card class="p-b-2">
        We host our App, Site Services, and any and all databases and servers in
        the United States. If you are an individual located in the European
        Union, the United Kingdom, Canada or another jurisdiction outside of the
        United States with laws and regulations governing Personal Information
        collection, use, and disclosure that differ from United States laws, you
        should not use our Site or Service or provide us with any of your
        information, whether Personal Information or otherwise. Please be aware
        that information we collect (including through the use of methods such
        as Cookies and other web technologies) will be processed and stored in
        the United States or in other countries where we or our third-party
        Service Providers have operations. By using Lint and submitting Personal
        Information, Users give their express consent to have it transferred to,
        processed, and stored in the United States or other jurisdictions which
        may not offer the same privacy protections available in your local
        jurisdiction or country of citizenship/residence.
      </Card>
      <Headline size="small">Do Not Track</Headline>
      <Card class="p-b-2">
        We do not support “Do Not Track” requests. To determine whether any of
        the third-party services we use honor “Do Not Track” requests, please
        read the individual privacy policy for each such service.
      </Card>
      <Headline size="small">Dispute Resolution</Headline>
      <Card class="p-b-2">
        To learn more about the Lint dispute resolution process, please see the
        lint-dev.com/terms-of-service.
      </Card>
      <Headline size="small">Notice to California Residents</Headline>
      <Card class="p-b-2">
        California laws, including the California Consumer Privacy Act and the
        “Shine the Light” law, give California residents certain rights
        regarding use and disclosure of “personal information” (as defined under
        California law). Lint will comply with all relevant California privacy
        laws, and will not share a User’s “personal information” with third
        parties for their own direct marketing purposes.
      </Card>
      <Headline size="small">Notice to Nevada Residents</Headline>
      <Card class="p-b-2">
        Nevada residents may have the right to opt out of the sale of
        “personally identifiable information” for monetary consideration (as
        such terms are defined under Nevada law). While Lint does not engage in
        the practice of selling “personally identifiable information” for
        monetary consideration, Users may submit a request to Lint to opt out of
        any potential future sales under Nevada law by emailing us at
        alamus@umich.edu. Lint will take reasonable steps to authenticate the
        identity of the User to verify the request, comply with the User’s opt
        out of sales request, and respond to such request.
      </Card>
      <Headline size="small">Changes to this Policy</Headline>
      <Card class="p-b-2">
        This Policy is current as of the date first set forth above.
        <br />
        <br />
        Lint may change this Policy from time to time in its discretion,
        including if we change the way we handle information. We may also change
        this Policy to make clarifications or adjustments. If we make such
        changes, we will post them here. You are responsible for checking this
        page for such changes. If you continue using Lint after changes are made
        effective, you agree to the revised Policy.
      </Card>
      <Headline size="small">Contact Us</Headline>
      <Card class="p-b-2">
        If you have any questions or comments about this Policy, please contact
        us at alamus@umich.edu.
      </Card>
    </Fragment>
  );
};
