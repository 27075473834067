import axiosConfig from "../axiosConfig";
import authHeader from './auth-header';

class CategoriesService {

  getCategories() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('categories/' + id, { headers: authHeader() });
  }

  getAllocationCategories() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('categories/allocation/' + id, { headers: authHeader() });
  }

  getMonthlyCategories() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('categories/monthly/' + id, { headers: authHeader() });
  }

  postCategory(category_code, category_name) {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('categories/' + id, {"category_code": category_code, "category_name": category_name}, { headers: authHeader() });
  };

  getCategory(id) {
    return axiosConfig.get('categories/category/' + id, { headers: authHeader() });
  }
}

export default new CategoriesService();