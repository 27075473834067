class IdleTimer {
    constructor({ timeout, grace, onTimeout, onExpired }) {
        this.timeout = timeout;
        this.grace = grace;
        this.onTimeout = onTimeout;
        this.onExpired = onExpired;
        this.gracePeriod = false;

        const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10);
        if (expiredTime > 0 && expiredTime < Date.now()) {
            this.onExpired();
            this.cleanUp();
            return;
        }
        
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }

    updateExpiredTime() {
        if (this.gracePeriod) {
            return;
        }
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
        }, 300);
    }

    updateGraceTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = localStorage.setItem("_expiredTime", Date.now() + this.grace * 1000);
    }

    startInterval() {
        this.updateExpiredTime();
        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10);
            if (!this.gracePeriod && expiredTime > 0 && expiredTime < Date.now()) {
                this.onTimeout();
                this.gracePeriod = true;
                this.updateGraceTime();
            } else if (expiredTime > 0 && expiredTime < Date.now()) {
                this.onExpired();
                this.cleanUp();
            }
        }, 1000);
    }

    getTime() {
        const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10)
        return max(expiredTime - Date.now(), 0)
    }

    tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp() {
        this.gracePeriod = false;
        localStorage.removeItem("_expiredTime");
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
}
export default IdleTimer;