import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Layout,
  Page,
  Stack,
  StatusLight,
} from "@servicetitan/design-system";
import { observer } from "mobx-react";
import { LoginStore } from "../stores/login.store";

let store = new LoginStore();

export const ForgotPasswordComponent = observer(
  function ForgotPasswordComponent() {
    const token = location.pathname.slice(
      location.pathname.lastIndexOf("/") + 1
    );
    return (
      <Page maxWidth="narrow">
        <Layout type="island" className="m-x-5">
          <Layout.Section className="m-x-5">
            <Card elevation="raised" light className="m-x-5">
              <img
                src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                alt="profile-img"
                className="profile-img-card m-t-5"
              />
              <Stack direction="column" alignContent="center" spacing={2}>
                <Stack.Item>
                  <Form.Input
                    label="New Password"
                    value={store.password}
                    onChange={store.setPassword}
                    placeholder="New Password"
                    required
                    type="password"
                  />
                </Stack.Item>
                <Stack.Item>
                  <Form.Input
                    label="Confirm New Password"
                    value={store.confirmPassword}
                    onChange={store.setConfirmPassword}
                    placeholder="Confirm New Password"
                    small
                    required
                    type="password"
                    error={
                      store.password.length > 0 &&
                      store.password !== store.confirmPassword &&
                      "Your passwords don't match.."
                    }
                  />
                </Stack.Item>
                <Stack.Item>
                  <Button
                    onClick={() => store.resetPassword(token)}
                    primary
                    disabled={
                      !(
                        store.password.length >= 6 &&
                        store.password === store.confirmPassword
                      )
                    }
                  >
                    Change Password
                  </Button>
                </Stack.Item>
                <Stack.Item>
                  {store.password.length >= 6 &&
                    store.password === store.confirmPassword && (
                      <Stack.Item>
                        <StatusLight color="success">
                          Ready to change to new password.
                        </StatusLight>
                      </Stack.Item>
                    )}
                </Stack.Item>
              </Stack>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }
);
