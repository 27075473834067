import axiosConfig from "../axiosConfig";
import authHeader from './auth-header';

class AccountsService {

  getAccounts() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('accounts/' + id, { headers: authHeader() });
  }

  getMonthlyAccounts() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('accounts/monthly/' + id, { headers: authHeader() });
  }

  getAccount(id) {
    return axiosConfig.get('accounts/account/' + id, { headers: authHeader() });
  }

  postAccount(account_code, account_name, bank_id) {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('accounts/' + id, {"account_code": account_code, "account_name": account_name, "bank_id": bank_id}, { headers: authHeader() });
  };
}

export default new AccountsService();