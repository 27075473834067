import axiosConfig from "../../axiosConfig";
import authHeader from "../../services/auth-header";
import { AxiosResponse } from "axios";
import { TransactionModel } from "../../transaction/api/transactions.api";

export interface ICategories {
  getCategory(category_id: number): Promise<AxiosResponse<CategoryModel>>;
  getCategories(): Promise<AxiosResponse<CategoryModel[]>>;
  getCategoryTransactions(category_id: number, startDate: string, endDate: string): Promise<AxiosResponse<CategoryTransaction[]>>;
  transfer(transfers: TransactionModel[]): Promise<AxiosResponse<TransactionModel[]>>;
  updateCategory(category: CategoryModel): Promise<AxiosResponse<CategoryModel>>;
}

export class Categories implements ICategories {

  getCategory(category_id: number) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("category/get/" + id, { headers: authHeader(), params: {category_id: category_id}});
  }

  getCategories() {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("categories/" + id, { headers: authHeader() });
  }

  getCategoryTransactions(category_id: number, startDate: string | undefined, endDate: string | undefined) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get('category/' + id, { headers: authHeader(), params: {start: startDate, end: endDate, category_id: category_id}});
  }

  transfer(transfers: TransactionModel[]) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.post(
      "account/" + id,
      [{
        id: 0,
        account_id: transfers[0].account_id,
        category_id: transfers[0].category_id,
        amount: transfers[0].amount,
        message: transfers[0].message,
        transfer_category_id: transfers[0].transfer_category_id,
        transaction_date: transfers[0].transaction_date,
        transaction_type_id: transfers[0].transaction_type_id,
        is_active: true,
      },
      {
        id: 0,
        account_id: transfers[1].account_id,
        category_id: transfers[1].category_id,
        amount: transfers[1].amount,
        message: transfers[1].message,
        transfer_category_id: transfers[1].transfer_category_id,
        transaction_date: transfers[1].transaction_date,
        transaction_type_id: transfers[1].transaction_type_id,
        is_active: true,
      },
    ],
      { headers: authHeader() }
    );
  }

  updateCategory(category: CategoryModel) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.post(
      "category/update/" + id,
      {
        id: category.id,
        category_name: category.category_name,
        category_code: category.category_code,
        category_type: category.category_type,
        budget_amount: category.budget_amount,
        budget_category_id: category.budget_category_id,
        goal_amount: category.goal_amount,
        is_active: category.is_active,
      },
      { headers: authHeader() }
    );
  }
}

export interface CategoryModel {
    id: number;
    category_name: string;
    category_code: string | undefined;
    plaid_category_name: string | undefined;
    category_type: CategoryType | undefined;
    budget_amount: number | undefined;
    budget_category_id: number | undefined;
    goal_amount: number | undefined;
    balance: number | undefined;
    allocated: number | undefined;
    spending: number| undefined;
    is_active: boolean;
}

export interface CategoryTransaction {
  id: number;
  amount: number;
  account_id: number;
  category_id: number;
  category_name: string | undefined;
  transaction_type_id: number;
  plaid_transaction_id: string | undefined;
  transfer_category_id: number | undefined;
  transfer_category_name: string | undefined;
  is_active: boolean;
}

export enum CategoryType {
  Income = 1,
  Budget = 2,
  Goal = 3,
}