import { combineReducers } from 'redux'
 
import categoriesReducer from './slices/categories-slice'
import homeReducer from './slices/home-slice'
import plaidReducer from './slices/plaid-slice'
import categoryTransactionsReducer from './slices/category-transactions-slice'
import accountTransactionsSlice from './slices/account-transactions-slice'

const rootReducer = combineReducers({
  // Define a top-level state fields
    categories: categoriesReducer,
    home: homeReducer,
    plaid: plaidReducer,
    categoryTransactions: categoryTransactionsReducer,
    accountTransactions: accountTransactionsSlice
})

export default rootReducer