import {
  BackLink,
  BodyText,
  Card,
  Form,
  Headline,
  Stack,
} from "@servicetitan/design-system";
import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { CategoriesStore } from "../stores/categories.store";
import { CategoryType } from "../api/categories.api";

let store = new CategoriesStore();

export const CategoryComponent = observer(function CategoryComponent() {
  const categoryId = React.useState(
    Number(location.pathname.slice(location.pathname.lastIndexOf("/") + 1))
  );

  useEffect(() => {
    store.getCategory(Number(categoryId[0]));
    store.getCategories();
  }, [store]);

  return (
    <Fragment>
      <Card>
        <br />
        <Stack direction="column-reverse" spacing="3">
          <Stack.Item>
            <Headline>Category</Headline>
          </Stack.Item>
          <Stack.Item>
            <BackLink pageLabel="Categories" href={"/categories/"} />
          </Stack.Item>
        </Stack>

        {categoryId[0] == 0 && (
          <BodyText subdued> Create your new category. </BodyText>
        )}
        {categoryId[0] > 0 && (
          <BodyText subdued> Edit your existing category. </BodyText>
        )}
        <Form>
            <Form.Group widths="equal">
            <Form.Input
            label="Name"
            value={store.newCategoryName}
            onChange={store.setNewCategoryName}
            placeholder="Name"
            description="Name for your category"
            required
          />
          <Form.Input
            label="Code"
            value={store.newCategoryCode}
            onChange={store.setNewCategoryCode}
            placeholder="Code"
            description="Add on for name and used for mobile"
          />
          
            </Form.Group>
            <Form.Group widths="equal">
          <Form.AnvilSelect
              value={store.newCategoryType}
              options={store.categoryTypes}
              label="Category Type"
              trigger={{ placeholder: "Select a category type" }}
              description="Label says it all"
              onChange={(data) => store.setNewCategoryType(data)}
            />
            {(store.newCategoryType == CategoryType.Budget || store.newCategoryType == CategoryType.Goal) && (
                <Form.Input
                label="Budget Amount"
                value={store.newCategoryBudgetAmount}
                onChange={store.setNewCategoryBudgetAmount}
                placeholder="Budget"
                description="Amount to Budget each month"
                required
              />
            )}
            {(store.newCategoryType == CategoryType.Budget || store.newCategoryType == CategoryType.Goal) && (
              <Form.AnvilSelect
                  value={store.newCategoryBudgetCategoryId}
                  options={store.displayIncomeCategories}
                  label="Budget Income Category"
                  trigger={{ placeholder: "Select a budget income category" }}
                  description="Income category to budget from"
                  onChange={(data) => store.setNewBudgetIncomeCategory(data)}
                  required
                />
            )}
            {store.newCategoryType == CategoryType.Goal && (
                <Form.Input
                label="Goal Amount"
                value={store.newCategoryGoalAmount}
                onChange={store.setNewCategoryGoalAmount}
                placeholder="Goal"
                description="Amount to set as Goal"
                required
              />
            )}
            
          </Form.Group>
          
          <Form.Button primary onClick={store.save} disabled={!store.newCategoryName || ((store.newCategoryType === CategoryType.Budget || store.newCategoryType === CategoryType.Goal) && !(store.newCategoryBudgetCategoryId > 0))}>
            Save
          </Form.Button>
        </Form>
      </Card>   
    </Fragment>
  );
});
