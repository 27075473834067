import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Layout,
  Page,
  Stack,
  StatusLight,
} from "@servicetitan/design-system";
import { observer } from "mobx-react";
import { LoginStore } from "../stores/login.store";

let store = new LoginStore();

export const RegisterComponent = observer(function RegisterComponent() {
  return (
    <Page maxWidth="narrow">
      <Layout type="island" className="m-x-5">
        <Layout.Section className="m-x-5">
          <Card elevation="raised" light className="m-x-5">
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card m-t-5"
            />
            <Stack direction="column" alignContent="center" spacing={2}>
              <Stack.Item>
              <Form.Input
                  label="Name"
                  value={store.name}
                  onChange={store.setName}
                  placeholder="Name"
                  required
                />
                <Form.Input
                  label="Username"
                  value={store.username}
                  onChange={store.setUsername}
                  placeholder="Username"
                  required
                  onBlur={store.checkUserTaken}
                  error={
                    (store.username.length > 0 &&
                      store.username.length < 5 &&
                      "Username must be at least 5 characters..") ||
                    (store.userTaken && "User is taken..")
                  }
                />
              </Stack.Item>
              <Stack.Item>
                <Form.Input
                  label="Email"
                  value={store.email}
                  onChange={store.setEmail}
                  placeholder="Email"
                  required
                  error={
                    store.email.length > 0 &&
                    !/(.+)@(.+){2,}\.(.+){2,}/.test(store.email) &&
                    "Not a possible email.."
                  }
                />
              </Stack.Item>
              <Stack.Item>
                <Form.Input
                  label="Password"
                  value={store.password}
                  onChange={store.setPassword}
                  placeholder="Password"
                  required
                  type="password"
                />
              </Stack.Item>
              <Stack.Item>
                <Form.Input
                  label="Confirm Password"
                  value={store.confirmPassword}
                  onChange={store.setConfirmPassword}
                  placeholder="Confirm Password"
                  small
                  required
                  type="password"
                  error={
                    store.password.length > 0 &&
                    store.password !== store.confirmPassword &&
                    "Your passwords don't match.."
                  }
                />
              </Stack.Item>
              <Stack.Item>
                <Button
                  onClick={store.register}
                  primary
                  disabled={
                    store.name.length === 0 ||
                    store.username.length === 0 ||
                    store.email.length === 0 ||
                    !(
                      store.password.length >= 6 &&
                      store.password === store.confirmPassword
                    ) ||
                    store.userTaken
                  }
                >
                  Sign Up
                </Button>
              </Stack.Item>
              <Stack.Item>
                {store.username.length >= 5 &&
                  store.email.length >= 5 &&
                  store.password.length >= 6 &&
                  store.password === store.confirmPassword &&
                  !store.userTaken && (
                    <Stack.Item>
                      <StatusLight color="success">
                        Ready for sign up
                      </StatusLight>
                    </Stack.Item>
                  )}
              </Stack.Item>
            </Stack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
});
