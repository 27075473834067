import {
  BackLink,
  BodyText,
  Card,
  Form,
  Headline,
  Stack,
} from "@servicetitan/design-system";
import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { AccountsStore } from "../stores/accounts.store";

let store = new AccountsStore();

export const AccountComponent = observer(function AccountComponent() {
  const accountId = React.useState(
    Number(location.pathname.slice(location.pathname.lastIndexOf("/") + 1))
  );
  useEffect(() => {
    store.getAccount(Number(accountId[0]));
  }, [store]);

  return (
    <Fragment>
      <Card>
        <br />
        <Stack direction="column-reverse" spacing="3">
          <Stack.Item>
            <Headline>Account</Headline>
          </Stack.Item>
          <Stack.Item>
            <BackLink pageLabel="Accounts" href={"/accounts/"} />
          </Stack.Item>
        </Stack>

        {accountId[0] == 0 && (
          <BodyText subdued>
            {" "}
            Create your new account (e.g. Chase Credit Card). It could be a
            bank, credit, or investment. To link to a bank account click on the Integration tab.{" "}
          </BodyText>
        )}
        {accountId[0] > 0 && (
          <BodyText subdued>
            {" "}
            Edit your existing account (e.g. Chase Credit Card). To link to a bank account click on the Integration tab.{" "}
          </BodyText>
        )}
        <Form className="s-t-3">
          <Form.Group widths="2">
            <Form.Input
              label="Account Name"
              value={store.newAccountName}
              onChange={store.setNewAccountName}
              placeholder="Account Name"
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.AnvilSelect
              value={store.newAccountType}
              options={store.accountTypes}
              label="Account Type"
              trigger={{ placeholder: "Select an account type" }}
              required
              onChange={(data) => store.setNewAccountType(data)}
              description="Could be a bank, credit card, or investment."
            />
            <Form.AnvilSelect
              value={store.newAccountPlaidItem}
              options={store.plaidItems}
              label="Link to Bank"
              trigger={{ placeholder: "Select a bank" }}
              onChange={(data) => store.setNewAccountPlaidItem(data)}
              description="Link to real account, integration only."
            />
            <Form.AnvilSelect
              value={store.newAccountPlaidItemAccountId}
              options={store.plaidAccounts}
              label="Link to Account"
              trigger={{ placeholder: "Select a linked account" }}
              disabled={
                !store.newAccountPlaidItem || !(store.plaidAccounts.length > 0)
              }
              onChange={(data) => store.setNewAccountPlaidItemAccountId(data)}
              description="Link to account within real bank, integration only."
            />
          </Form.Group>
          <Form.Group>
            <Form.Button onClick={store.save} disabled={!store.newAccountName || !(store.newAccountType > 0)} primary>
              Save Account
            </Form.Button>
          </Form.Group>
        </Form>
      </Card>
    </Fragment>
  );
});
