import {
  ActionMenu,
  BackLink,
  BodyText,
  Button,
  ButtonGroup,
  Card,
  Form,
  Headline,
  Link,
  Stack,
  Tab,
  TabGroup,
  Tag,
  ToggleSwitch,
  Tooltip,
} from "@servicetitan/design-system";
import { Table, TableColumn } from "@servicetitan/table";
import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { AccountTransactionsStore } from "../stores/account-transactions.store";
import Moment from "react-moment";

let store = new AccountTransactionsStore();

export const AccountTransactionsComponent = observer(
  function AccountTransactionsComponent() {
    const accountId = React.useState(
      Number(location.pathname.slice(location.pathname.lastIndexOf("/") + 1))
    );
    useEffect(() => {
      store.getAccount(Number(accountId[0]));
    }, [store]);

    const ActionCell = (props: any) => {
      const styles = props.style;
      return (
        <td className="k-grid-content-sticky" style={{ ...styles }}>
          <ButtonGroup>
            <Tooltip text="Edit" portal>
              <Button
                xsmall
                href={
                  "/account/" +
                  props.dataItem.account_id +
                  "/transaction/" +
                  props.dataItem.id
                }
                fill="subtle"
                iconName="edit"
              />
            </Tooltip>
            {props.dataItem.is_active && (
              <Tooltip text="Delete" portal>
                <Button
                  onClick={() => store.deleteTransaction(props.dataItem)}
                  xsmall
                  color="red"
                  fill="subtle"
                  iconName="delete"
                />
              </Tooltip>
            )}
            {!props.dataItem.is_active && (
              <Tooltip text="Restore" portal>
                <Button
                  onClick={() => store.restoreTransaction(props.dataItem)}
                  xsmall
                  color="primary"
                  fill="subtle"
                  iconName="restore"
                />
              </Tooltip>
            )}
          </ButtonGroup>
        </td>
      );
    };

    return (
      <Fragment>
        <Card>
          <Headline>{store.account?.account_name}</Headline>
          <Form>
            <Form.Group widths="equal">
              <Form.DatePicker
                //label="Start"
                description="Search transactions starting here"
                value={store.startDate}
                onChange={(v) => store.setStartDate(v)}
              />
              <Form.DatePicker
                //label="End"
                description="Search transactions ending here"
                value={store.endDate}
                onChange={(v) => store.setEndDate(v)}
              />
              <Form.Button
                onClick={() =>
                  store.getAccountTransactions(store.account?.id || 0)
                }
                color="primary"
              >
                Search
              </Form.Button>
            </Form.Group>
          </Form>
          <br />
          <Headline>Transactions</Headline>
          <Stack direction="column" spacing={3}>
            <Stack.Item>
              <TabGroup
                action={
                  <Stack direction="row" spacing={2}>
                    <ToggleSwitch
                      small
                      label="Show Deleted"
                      checked={store.showDeletedToggle}
                      onChange={store.toggleShowDeleted}
                    />
                    <Button
                      xsmall
                      primary
                      href={"/account/" + accountId[0] + "/transaction/" + 0}
                    >
                      Create New Transaction
                    </Button>
                  </Stack>
                }
                children={undefined}
              ></TabGroup>
            </Stack.Item>
            <Stack.Item>
              <Table
                tableState={store.tableState}
                take={10}
                striped={true}
                loading={store.loading}
              >
                <TableColumn
                  field="category_name"
                  title="Category"
                  width="125px"
                  format="{0:c}"
                  cell={(props) => (
                    <td>
                      <Link
                        color="primary"
                        className="c-blue-400-i"
                        href={"/categories/" + props.dataItem.category_id}
                      >
                        {props.dataItem.category_name}
                      </Link>
                    </td>
                  )}
                />
                <TableColumn
                  field="transfer_category_name"
                  title={"Transfer"}
                  width="125px"
                  format="{0:c}"
                  cell={(props) => (
                    <td>
                      <Link
                        color="primary"
                        className="c-blue-400-i"
                        href={
                          "/categories/" + props.dataItem.transfer_category_id
                        }
                      >
                        {props.dataItem.transfer_category_name}
                      </Link>
                    </td>
                  )}
                />
                <TableColumn
                  field="merchant_name"
                  title="Merchant"
                  width="250px"
                  format="{0:c}"
                />
                <TableColumn
                  field="message"
                  title="Message"
                  width="250px"
                  format="{0:c}"
                />
                <TableColumn
                  field="amount"
                  title="Amount"
                  width="100px"
                  format="{0:c}"
                />
                <TableColumn
                  field="balance"
                  title="Balance"
                  width="100px"
                  format="{0:c}"
                />
                <TableColumn
                  field="transaction_date"
                  title="Transaction Date"
                  width="160px"
                  // format="{0:MM/dd/yy}"
                  cell={(props) => (
                    <td>
                      {props.dataItem.transaction_date && (
                        <Moment
                          format="MM/DD/YYYY"
                          date={props.dataItem.transaction_date[0]}
                        />
                      )}
                    </td>
                  )}
                />
                <TableColumn
                  field="date_changed"
                  title="Budgeted"
                  width="100px"
                  // format="{0:MM/dd/yy}"
                  cell={(props) => (
                    <td>
                      <Moment
                        format="MM/DD/YYYY"
                        date={props.dataItem.date_changed[0]}
                      />
                    </td>
                  )}
                />
                <TableColumn
                  locked
                  field="actions"
                  title="Actions"
                  width="100px"
                  cell={ActionCell}
                />
              </Table>
            </Stack.Item>
          </Stack>
        </Card>
      </Fragment>
    );
  }
);
