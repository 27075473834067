import { action, makeObservable, observable, runInAction } from "mobx";
import { FormEvent } from "react";
import { AccountModel } from "../../accounts/api/accounts.api";
import {
  TransactionModel,
  Transactions,
} from "../../transaction/api/transactions.api";
import { Categories, CategoryModel, CategoryType } from "../api/categories.api";

export class CategoriesStore {
  @observable category: CategoryModel | undefined = undefined;
  @observable categories: CategoryModel[] = [];
  @observable accounts: AccountModel[] = [];
  @observable displayCategories: CategoryModel[] = [];
  @observable fromCategory: CategoryModel | undefined = undefined;
  @observable toCategory: CategoryModel | undefined = undefined;
  @observable transferAmount: string = "0";
  @observable transferMessage: string = "";
  @observable transferDate: Date | undefined = undefined;
  @observable transferAccountId: number = 0;
  @observable newCategoryName: string | undefined = undefined;
  @observable newCategoryCode: string | undefined = undefined;
  @observable newCategoryBudgetAmount: string = '0';
  @observable newCategoryBudgetCategoryId: number = 0;
  @observable newCategoryGoalAmount: string = '0';
  @observable newCategoryType: CategoryType | undefined = undefined;
  @observable newCategoryActive: boolean = true;
  @observable showDeletedToggle: boolean = false;
  @observable incomeCategories: CategoryModel[] = [];
  @observable displayIncomeCategories: any = [];
  @observable showTransferToggle: boolean = false;
  @observable totalBudgeted: number = 0;
  @observable totalIncomeBalance: number = 0;
  categoriesApi: Categories = new Categories();
  transactionApi: Transactions = new Transactions();
  categoryTypes: any = [
    { text: "Income", value: CategoryType.Income },
    { text: "Budget", value: CategoryType.Budget },
    { text: "Goal", value: CategoryType.Goal },
  ];

  constructor() {
    makeObservable(this);
  }

  @action
  getAccounts = async () => {
    const accounts = await this.transactionApi.getAccounts();
    runInAction(() => {
      if (accounts.data) {
        this.accounts = accounts.data;
        const idx = this.accounts.findIndex((x) => x.account_code === "TR");
        this.transferAccountId = this.accounts[idx].id;
      }
    });
  };

  @action
  getCategories = async () => {
    const categories = await this.categoriesApi.getCategories();
    runInAction(() => {
      if (categories.data) {
        this.categories = categories.data;
        this.setActiveCategories();
        this.setIncomeCategories();
        this.setTotalBudgeted();
        this.setTotalIncomeBalance();
      }
    });
  };

  @action
  getCategory = async (id: number) => {
    if (id <= 0) return;
    const category = await this.categoriesApi.getCategory(id);
    runInAction(() => {
      if (category.data) {
        this.category = category.data;
        this.newCategoryName = this.category?.category_name;
        this.newCategoryCode = this.category?.category_code;
        this.newCategoryType = this.category?.category_type;
        this.newCategoryBudgetAmount = this.category?.budget_amount?.toString() || '0';
        this.newCategoryBudgetCategoryId = this.category?.budget_category_id || 0;
        this.newCategoryGoalAmount = this.category?.goal_amount?.toString() || '0';
        this.newCategoryActive = this.category?.is_active || false;
      }
    });
  };

  @action
  selectFrom = (category: CategoryModel) => {
    this.fromCategory = category;
  };

  @action
  selectTo = (category: CategoryModel) => {
    this.toCategory = category;
  };

  @action
  resetTransferCategories = () => {
    this.fromCategory = undefined;
    this.toCategory = undefined;
    this.transferMessage = "";
    this.transferAmount = "0";
    this.transferDate = undefined;
  };

  @action
  setTransferAmount = (event: FormEvent<HTMLInputElement>) => {
    this.transferAmount = event.currentTarget.value.replace(/[^0-9\.-]+/g, '');
  };

  @action
  setTransferMessage = (event: FormEvent<HTMLInputElement>) => {
    this.transferMessage = event.currentTarget.value;
  };

  @action
  setTransferDate = (date: Date | undefined) => {
    this.transferDate = date;
  };

  @action
  transfer = async () => {
    const transfers: TransactionModel[] = [];
    const fromModel: TransactionModel = {
      id: 0,
      account_id: this.transferAccountId,
      category_id: this.fromCategory?.id || 0,
      message: this.transferMessage,
      transfer_category_id: this.toCategory?.id || 0,
      transaction_date: this.transferDate?.toISOString().split("T")[0],
      is_active: true,
      amount: Number(this.transferAmount) * -1,
      plaid_transaction_id: undefined,
      date_changed: "",
      transaction_type_id: 3,
    };
    transfers.push(fromModel);
    const toModel: TransactionModel = {
      id: 0,
      account_id: this.transferAccountId,
      category_id: this.toCategory?.id || 0,
      message: this.transferMessage,
      transfer_category_id: this.fromCategory?.id || 0,
      transaction_date: this.transferDate?.toISOString().split("T")[0],
      is_active: true,
      amount: Number(this.transferAmount),
      plaid_transaction_id: undefined,
      date_changed: "",
      transaction_type_id: 3,
    };
    transfers.push(toModel);
    await this.categoriesApi.transfer(transfers);
    runInAction(() => {
      this.getCategories();
    });
  };

  @action
  setNewCategoryName = (event: FormEvent<HTMLInputElement>) => {
    this.newCategoryName = event.currentTarget.value;
  };

  @action
  setNewCategoryCode = (event: FormEvent<HTMLInputElement>) => {
    this.newCategoryCode = event.currentTarget.value;
  };

  @action
  setNewCategoryBudgetAmount = (event: FormEvent<HTMLInputElement>) => {
    this.newCategoryBudgetAmount = event.currentTarget.value.replace(/[^0-9\.-]+/g, '');
  };

  @action
  setNewCategoryGoalAmount = (event: FormEvent<HTMLInputElement>) => {
    this.newCategoryGoalAmount = event.currentTarget.value.replace(/[^0-9\.-]+/g, '');
  };

  @action
  setNewCategoryType = (data: any) => {
    this.newCategoryType = data?.value;
  };

  @action
  setNewBudgetIncomeCategory = (data: any) => {
    this.newCategoryBudgetCategoryId = data?.value;
  };

  @action
  save = async () => {
    const categoryModel: CategoryModel = {
        id: this.category?.id || 0,
        category_name: this.newCategoryName || "",
        is_active: this.newCategoryActive,
        balance: undefined,
        allocated: undefined,
        category_code: this.newCategoryCode,
        plaid_category_name: undefined,
        category_type: this.newCategoryType,
        budget_amount: Number(this.newCategoryBudgetAmount),
        goal_amount: Number(this.newCategoryGoalAmount),
        budget_category_id: this.newCategoryBudgetCategoryId,
        spending: undefined
    };
    await this.categoriesApi.updateCategory(categoryModel);
    runInAction(() => {
      window.open("/categories/", "_self");
    });
  };

  @action showDeleted = () => {
    this.displayCategories = this.categories;
  };

  @action showActive = () => {
    this.displayCategories = this.categories.filter((item) => {
      return item.is_active == true;
    });
  };

  @action
  toggleShowDeleted = () => {
    this.showDeletedToggle = !this.showDeletedToggle;
    this.setActiveCategories();
  };

  @action
  toggleShowTransfer = () => {
    this.showTransferToggle = !this.showTransferToggle;
  };

  @action
  deleteCategory = async (category: CategoryModel) => {
    category.is_active = false;
    await this.categoriesApi.updateCategory(category);
    runInAction(() => {
      this.getCategories();
    });
  };

  @action
  restoreCategory = async (category: CategoryModel) => {
    category.is_active = true;
    await this.categoriesApi.updateCategory(category);
    runInAction(() => {
      this.getCategories();
    });
  };

  setActiveCategories = () => {
    this.showDeletedToggle ? this.showDeleted() : this.showActive();

  };

  setIncomeCategories = () => {
    this.incomeCategories = this.categories.filter((item) => {
      return (
        item.is_active == true && item.category_type == CategoryType.Income
      );
    });
    this.displayIncomeCategories = [];
    this.incomeCategories.forEach((item: CategoryModel) => {
        this.displayIncomeCategories.push({text: item.category_name, value: item.id})
    })
  };

  setTotalBudgeted = () => {
    this.totalBudgeted = 0;
    this.displayCategories.forEach((item: CategoryModel) => {
        this.totalBudgeted += (item?.budget_amount || 0);
    });
  };

  setTotalIncomeBalance = () => {
      this.totalIncomeBalance = 0;
      this.incomeCategories.forEach((item: CategoryModel) => {
        this.totalIncomeBalance += (item?.balance || 0);
    });
  }

}
