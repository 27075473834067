import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CloseMonthService from "../services/close-month-service";
import CategoriesService from "../services/category-service";
import AccountsService from "../services/accounts-service";
import AllocationService from "../services/allocation-service";

const initialState = {
  monthlyCategories: [],
  monthlyAccounts: [],
  status: 'idle',
  error: null
}

export const fetchCategoriesMonthly = createAsyncThunk('categoriesMonthly', async () => {
  const response = await CategoriesService.getMonthlyCategories();
  return response.data
});

export const fetchAccountsMonthly = createAsyncThunk('accountssMonthly', async () => {
    const response = await AccountsService.getMonthlyAccounts();
    return response.data
  });

export const addAllocation = createAsyncThunk(
  'allocation',
  // The payload creator receives the partial object
  async initialAllocation => {
    const response = await AllocationService.postAllocation()
    // The response includes the complete post object, including unique ID
    return response.data
  }
)

export const addCloseMonth = createAsyncThunk(
    'closeMonth',
    // The payload creator receives the partial object
    async initialCloseMonth => {
      const response = await CloseMonthService.postCloseMonth()
      // The response includes the complete post object, including unique ID
      return response.data
    }
  )

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
      monthlyCategoriesAdded: {
        reducer(state, action) {
          state.monthlyCategories.push(action.payload)
        },
        prepare(title, content, userId) {
          // omit prepare logic
        }
      },
      monthlyAccountsAdded: {
        reducer(state, action) {
          state.monthlyAccounts.push(action.payload)
        },
        prepare(title, content, userId) {
          // omit prepare logic
        }
      },
    },
    extraReducers: {
      [fetchCategoriesMonthly.pending]: (state, action) => {
        state.status = 'loading'
      },
      [fetchCategoriesMonthly.fulfilled]: (state, action) => {
        state.status = 'category success'
        // Add any fetched categories to the array
        state.monthlyCategories = action.payload
      },
      [fetchCategoriesMonthly.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
      [fetchAccountsMonthly.pending]: (state, action) => {
        state.status = 'loading'
      },
      [fetchAccountsMonthly.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched categories to the array
        state.monthlyAccounts = action.payload
      },
      [fetchAccountsMonthly.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
      [addAllocation.pending]: (state, action) => {
        state.status = 'loading'
      },
      [addAllocation.fulfilled]: (state, action) => {
        state.status = 'idle'
      },
      [addAllocation.rejected]: (state, action) => {
        state.error = action.error.message
      },
      [addCloseMonth.pending]: (state, action) => {
        state.status = 'loading'
      },
      [addCloseMonth.fulfilled]: (state, action) => {
        state.status = 'idle'
      },
      [addCloseMonth.rejected]: (state, action) => {
        state.error = action.error.message
      },
    }
  })
  
  export const { monthlyAccountsAdded, monthlyCategoriesAdded } = homeSlice.actions
  
  export default homeSlice.reducer
  
  export const selectAllMonthlyCategories = state => state.home.monthlyCategories

  export const selectAllMonthlyAccounts = state => state.home.monthlyAccounts
  