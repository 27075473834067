import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import accountTransactionsService from "../services/account-transactions-service";

const initialState = {
  accountTransactions: [],
  status: 'idle',
  error: null
}

export const fetchAccountTransactions = createAsyncThunk('accountTransactions', async ({id, startDate, endDate}) => {
  const response = await accountTransactionsService.getAccountTransactions(id, startDate, endDate);
  return response.data
});

export const fetchAccountAllocationTransactions = createAsyncThunk('accountAllocationTransactions', async () => {
    const response = await accountTransactionsService.getAccountAllocationTransactions();
    return response.data
  });

const accountTransactionsSlice = createSlice({
    name: 'accountTransactions',
    initialState,
    reducers: {
      accountTransactionsAdded: {
        reducer(state, action) {
          state.accountTransactions.push(action.payload)
        },
      },
      accountTransactionsUpdated(state, action) {
        const { id, name, content } = action.payload
        const existingAccount = state.accountTransactions.find(account => account.id === id)
        if (existingAccount) {
          existingAccount.name = name
          existingAccount.content = content
        }
      }
    },
    extraReducers: {
      [fetchAccountTransactions.pending]: (state, action) => {
        state.status = 'loading'
      },
      [fetchAccountTransactions.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched categories transactions to the array
        state.accountTransactions = action.payload
      },
      [fetchAccountTransactions.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
      [fetchAccountAllocationTransactions.pending]: (state, action) => {
        state.status = 'loading'
      },
      [fetchAccountAllocationTransactions.fulfilled]: (state, action) => {
        state.status = 'succeeded'
      },
      [fetchAccountAllocationTransactions.rejected]: (state, action) => {
        state.error = action.error.message
      }
    }
  })
  
  export const { accountTransactionsAdded, accountTransactionsUpdated } = accountTransactionsSlice.actions
  
  export default accountTransactionsSlice.reducer
  
  export const selectAllAccountTransactions = state => state.accountTransactions.accountTransactions