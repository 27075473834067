import axiosConfig from "../../axiosConfig";
import authHeader from "../../services/auth-header";
import { AxiosResponse } from "axios";

export interface IAccounts {
  getAccounts(): Promise<AxiosResponse<AccountModel[]>>;
  updateAccount(account: AccountModel): Promise<AxiosResponse<AccountModel>>;
  getPlaidItems(): Promise<AxiosResponse<PlaidItemModel[]>>;
  getAccount(account_id: number): Promise<AxiosResponse<AccountModel>>;
  getAccountTransactions(
    account_id: number,
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<AccountTransaction[]>>;
}

export class Accounts implements IAccounts {
  getAccounts() {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("accounts/" + id, { headers: authHeader() });
  }

  updateAccount(account: AccountModel) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.post(
      "account/update/" + id,
      {
        id: account.id,
        account_name: account.account_name,
        account_type: account.account_type,
        plaid_item_id: account.plaid_item_id,
        plaid_account_id: account.plaid_account_id,
        is_active: account.is_active,
      },
      { headers: authHeader() }
    );
  }

  getPlaidItems() {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("plaid_item/get_all_items/" + id, {
      headers: authHeader(),
    });
  }

  getAccount(account_id: number) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("account/get/" + id, {
      headers: authHeader(),
      params: { account_id: account_id },
    });
  }

  getPlaidItemTransactions(access_token: string) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.post(
      "plaid_item/get_item_transactions/" + id,
      { access_token: access_token },
      { headers: authHeader() }
    );
  }

  getAccountTransactions(
    account_id: number,
    startDate: string | undefined,
    endDate: string | undefined
  ) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("account/" + id, {
      headers: authHeader(),
      params: { start: startDate, end: endDate, account_id: account_id },
    });
  }
}

export interface AccountModel {
  id: number;
  account_name?: string | undefined;
  account_code?: string | undefined;
  account_type?: AccountType | undefined;
  plaid_item_id?: number | undefined;
  plaid_account_id: string | undefined;
  user_id: number;
  is_active?: boolean | undefined;
  date_changed: string;
}

export interface PlaidItemModel {
  id: number;
  institution_name?: string | undefined;
  access_token?: string | undefined;
}

export interface PlaidItemAccount {
  name: string;
  type: string;
  subtype: string;
  account_id: string;
  balances: PlaidItemBalance[];
}

export interface PlaidItemBalance {
  current: Number;
  available: Number;
  limit: Number;
}

export interface PlaidItemData {
  total_transaction: Number;
  accounts: PlaidItemAccount[];
}

export interface AccountTransaction {
  id: number;
  amount: number;
  account_id: number;
  category_id: number;
  category_name: string | undefined;
  merchant_name: string | undefined;
  transaction_type_id: number;
  plaid_transaction_id: string | undefined;
  transfer_category_id: number | undefined;
  transfer_category_name: string | undefined;
  is_active: boolean;
}

export enum AccountType {
  Bank = 1,
  CreditCard = 2,
  Investment = 3,
}
