import {
  ActionMenu,
  Avatar,
  BackLink,
  BodyText,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Form,
  Headline,
  Icon,
  InlineEdit,
  Input,
  Label,
  Link,
  Popover,
  Stack,
  Tab,
  TabGroup,
  Table,
  TableColumn,
  Tag,
  ToggleSwitch,
} from "@servicetitan/design-system";
import React, { Fragment, useEffect } from "react";
import { observer } from "mobx-react";
import { AccountsStore } from "../stores/accounts.store";
import { AccountType } from "../api/accounts.api";

let store = new AccountsStore();

export const AccountsComponent = observer(function AccountsComponent() {
  useEffect(() => {
    store.getAccounts();
  }, [store]);

  const ActionCell = (props: any) => {
    const styles = props.style;
    return (
      <td className="k-grid-content-sticky" style={{ ...styles }}>
        <ButtonGroup>
          <Button href={"/account/" + props.dataItem.id} xsmall>
            Edit
          </Button>
          {props.dataItem.is_active && (
            <Button
              color="red"
              onClick={() => store.deleteAccount(props.dataItem)}
              size="xsmall"
            >
              Delete
            </Button>
          )}
          {!props.dataItem.is_active && (
            <Button
              color="blue"
              onClick={() => store.activateAccount(props.dataItem)}
              size="xsmall"
            >
              Restore
            </Button>
          )}
        </ButtonGroup>
      </td>
    );
  };

  const CheckboxCell = (props: any) => {
    const styles = props.style;
    return (
      <td className="k-grid-content-sticky" style={{ ...styles }}>
        <Checkbox
          checked={props.dataItem.is_active}
          value={props.dataItem.is_active}
        ></Checkbox>
      </td>
    );
  };

  return (
    <React.Fragment>
      <Fragment>
        <Card>
          <Headline className="m-b-2 t-truncate" size="large">
            Accounts
          </Headline>
          <Stack direction="column" spacing={3}>
            <Stack.Item>
              <TabGroup
                action={
                  <Stack direction="row" spacing={2}>
                    <ToggleSwitch
                      small
                      label="Show Deleted"
                      checked={store.showDeletedToggle}
                      onChange={store.toggleShowDeleted}
                    />
                    <Button size="small" al primary href={"/account/" + 0}>
                      Create
                    </Button>
                  </Stack>
                }
              >
                <style>{`
                  .icon-hov {
                        cursor: pointer;
                    }
                    .icon-hov:hover svg { fill: #2270ee; }
                `}</style>
                <Tab
                  onClick={() => store.setTab(AccountType.Bank)}
                  active={store.selectedTab === AccountType.Bank}
                >
                  Banks{" "}
                  <Tag compact badge color="blue">
                    {store.banks.length}
                  </Tag>
                </Tab>
                <Tab
                  onClick={() => store.setTab(AccountType.CreditCard)}
                  active={store.selectedTab === AccountType.CreditCard}
                >
                  {" "}
                  Credit Cards{" "}
                  <Tag compact badge color="purple">
                    {store.creditCards.length}
                  </Tag>
                </Tab>
                <Tab
                  onClick={() => store.setTab(AccountType.Investment)}
                  active={store.selectedTab === AccountType.Investment}
                >
                  {" "}
                  Investments{" "}
                  <Tag compact badge color="green">
                    {store.investments.length}
                  </Tag>
                </Tab>
              </TabGroup>
            </Stack.Item>
            <Stack.Item>
              <Table
                striped={true}
                data={store.displayedAccounts}
                loading={!(store.accounts.length > 0)}
              >
                <TableColumn
                  field="account_name"
                  title="Name"
                  width="500px"
                  format="{0:c}"
                  cell={(props) => (
                    <td>
                      <Stack spacing={1} alignItems="center">
                        <Avatar
                          autoColor
                          size="s"
                          name={props.dataItem.account_name}
                        />
                        <Link
                          color="primary"
                          className="c-blue-400-i"
                          href={"/accounts/" + props.dataItem.id}
                        >
                          {props.dataItem.account_name}
                        </Link>
                        {props.dataItem.plaid_item_id > 0 && (
                          <Icon
                            color="green"
                            size={20}
                            name="insert_link"
                          ></Icon>
                        )}
                      </Stack>
                    </td>
                  )}
                />
                <TableColumn
                  field="balance"
                  title="Balance"
                  width="250px"
                  format="{0:c}"
                />
                {/* <TableColumn
                field="is_active"
                title="Active"
                width="100px"
                cell={CheckboxCell}
              /> */}
                <TableColumn
                  locked
                  field="actions"
                  title="Actions"
                  width="320px"
                  cell={ActionCell}
                />
              </Table>
            </Stack.Item>
          </Stack>
        </Card>
        <br />
      </Fragment>
    </React.Fragment>
  );
});
