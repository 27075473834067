import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CategoryTransactionsService from "../services/category-transactions-service";

const initialState = {
  categoryTransactions: [],
  status: 'idle',
  error: null
}

export const fetchCategoryTransactions = createAsyncThunk('categoryTransactions', async ({id, startDate, endDate}) => {
  const response = await CategoryTransactionsService.getCategoryTransactions(id, startDate, endDate);
  return response.data
});

export const fetchCategoryAllocationTransactions = createAsyncThunk('categoryAllocationTransactions', async () => {
    const response = await CategoryTransactionsService.getCategoryAllocationTransactions();
    return response.data
  });

const categoryTransactionsSlice = createSlice({
    name: 'categoryTransactions',
    initialState,
    reducers: {
      categoryTransactionsAdded: {
        reducer(state, action) {
          state.categoryTransactions.push(action.payload)
        },
      },
      categoryTransactionsUpdated(state, action) {
        const { id, name, content } = action.payload
        const existingCategory = state.categoryTransactions.find(category => category.id === id)
        if (existingCategory) {
          existingCategory.name = name
          existingCategory.content = content
        }
      }
    },
    extraReducers: {
      [fetchCategoryTransactions.pending]: (state, action) => {
        state.status = 'loading'
      },
      [fetchCategoryTransactions.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched categories transactions to the array
        state.categoryTransactions = action.payload
      },
      [fetchCategoryTransactions.rejected]: (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      },
      [fetchCategoryAllocationTransactions.pending]: (state, action) => {
        state.status = 'loading'
      },
      [fetchCategoryAllocationTransactions.fulfilled]: (state, action) => {
        state.status = 'succeeded'
      },
      [fetchCategoryAllocationTransactions.rejected]: (state, action) => {
        state.error = action.error.message
      }
    }
  })
  
  export const { categoryTransactionsAdded, categoryTransactionsUpdated } = categoryTransactionsSlice.actions
  
  export default categoryTransactionsSlice.reducer
  
  export const selectAllCategoryTransactions = state => state.categoryTransactions.categoryTransactions