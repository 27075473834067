import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import App from "./App";
import {
  Page
} from "@servicetitan/design-system";
import store from './store'
import "@servicetitan/anvil-fonts/dist/css/anvil-fonts.css";
import "@servicetitan/design-system/dist/system.css";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
    <Page>
      <App />
    </Page>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
