import {action, makeObservable, observable, runInAction } from 'mobx';
import { AccountModel, Accounts, AccountType, PlaidItemAccount, PlaidItemData, PlaidItemModel } from '../api/accounts.api';
import { InMemoryDataSource, TableState } from '@servicetitan/table';
import { FormEvent } from 'react';

export class AccountsStore {
    @observable accounts: AccountModel[] = [];
    @observable account: AccountModel | undefined = undefined;
    @observable displayedAccounts: AccountModel[] = [];
    @observable tableState: TableState<any, number>;
    @observable showDeletedToggle: boolean = false;
    @observable newAccountName: string = "";
    @observable newAccountType: AccountType = 0;
    @observable newAccountPlaidItemAccountId: string = "";
    @observable plaidItems: any = [];
    @observable newAccountPlaidItem: number = 0;
    @observable plaidAccountsData: PlaidItemModel[] = [];
    @observable plaidItemData: PlaidItemData  | undefined = undefined;
    @observable plaidItemAccounts: PlaidItemAccount[] = [];
    @observable plaidAccounts: any = [];
    @observable banks: AccountModel[] = [];
    @observable creditCards: AccountModel[] = [];
    @observable investments: AccountModel[] = [];
    @observable selectedTab = AccountType.Bank;

    accountApi: Accounts = new Accounts();
    accountTypes: any = [
        { text: 'Bank', value: AccountType.Bank},
        { text: 'Credit Card', value: AccountType.CreditCard},
        { text: 'Investment', value: AccountType.Investment},
    ]
    
    constructor() {
        makeObservable(this);
        this.tableState = new TableState<any, number>({
            pageSize: 5,
            dataSource: null,
        });
    }

    @action
    saveEditName = async (name: any, account: AccountModel) => {
        account.account_name = name;
        await this.accountApi.updateAccount(account)
        runInAction( () => {
            this.getAccounts();
        })
    };

    @action
    deleteAccount = async (account: AccountModel) => {
        account.is_active = false;
        await this.accountApi.updateAccount(account)
        runInAction( () => {
            this.getAccounts();
        })
    };

    @action
    activateAccount = async (account: AccountModel) => {
        account.is_active = true;
        await this.accountApi.updateAccount(account)
        runInAction( () => {
            this.getAccounts();
        })
    };

    @action
    toggleShowDeleted = () => {
        this.showDeletedToggle = !this.showDeletedToggle;
        this.splitAccounts();
    }

    @action showDeleted = () => {
        this.displayedAccounts = this.accounts;
    };

    @action showActive = () => {
        this.displayedAccounts = this.accounts.filter(item => { return item.is_active == true; });
    };

    @action
    getAccounts = async () => {
        const accounts = await this.accountApi.getAccounts();
        runInAction( () => {
            if (accounts.data) {
                this.accounts = accounts.data;
                this.splitAccounts()
            }
        })
    };

    @action
    getPlaidItems = async () => {
        const plaidAccounts = await this.accountApi.getPlaidItems()
        runInAction( () => {
            if (plaidAccounts.data) {
                this.plaidAccountsData = plaidAccounts.data;
                plaidAccounts.data.forEach((item: PlaidItemModel) => {
                    this.plaidItems.push({text: item.institution_name, value: item.id})
                })
            }
        })
    }

    @action
    getAccount = async (id: number) => {
        await this.getAccounts();
        await this.getPlaidItems();
        if (id <= 0) return;
        const account = await this.accountApi.getAccount(id);
        runInAction( () => {
            if (account.data) {
                this.account = account.data;
                this.newAccountName = this.account?.account_name || '';
                this.newAccountType = this.account?.account_type || AccountType.Bank;
                this.newAccountPlaidItem = this.account?.plaid_item_id || 0;
                this.newAccountPlaidItemAccountId = this.account?.plaid_account_id || '';
                if (this.newAccountPlaidItem > 0) {
                    this.getPlaidItemTransactions();
                }
            }
        })
    };

    @action
    setNewAccountName = (event: FormEvent<HTMLInputElement>) => {
        this.newAccountName = event.currentTarget.value;
    };

    @action
    setNewAccountType = (data: any) => {
        this.newAccountType = data?.value;
    };

    @action
    setNewAccountPlaidItemAccountId = (data: any) => {
        this.newAccountPlaidItemAccountId = data?.value;
    };

    @action
    setNewAccountPlaidItem = (data: any) => {
        this.plaidAccounts = [];
        this.newAccountPlaidItem = data?.value;
        this.getPlaidItemTransactions();
    };

    @action
    getPlaidItemTransactions = async() => {
        const idx = this.plaidAccountsData?.findIndex(x => x.id === this.newAccountPlaidItem);
        if (idx >= 0) {
            const access_token = this.plaidAccountsData[idx].access_token || '';
            const plaidItemTransactions = await this.accountApi.getPlaidItemTransactions(access_token)
            runInAction( () => {
                if (plaidItemTransactions.data) {
                    this.plaidItemData = plaidItemTransactions.data;
                    this.plaidItemAccounts = this.plaidItemData?.accounts || [];
                    this.plaidItemAccounts.forEach((item: PlaidItemAccount) => {
                        this.plaidAccounts.push({text: item.name, value: item.account_id})
                    })
                }
            })
        }
    };

    @action
    save = async () => {
        const accountModel: AccountModel = {
            id: this.account?.id || 0,
            account_name: this.newAccountName,
            account_code: '',
            account_type: this.newAccountType,
            plaid_item_id: this.newAccountPlaidItem,
            plaid_account_id: this.newAccountPlaidItemAccountId,
            is_active: this.account?.is_active,
            user_id: 0,
            date_changed: ''
        }
        await this.accountApi.updateAccount(accountModel)
        runInAction(() => {
            window.open("/accounts/", "_self");
        })
    };

    @action 
    setTab = (accountType: AccountType) => {
        this.selectedTab = accountType;
        this.splitAccounts();
    }

    splitAccounts = () => {
        this.showDeletedToggle ?  this.showDeleted() : this.showActive(); 
        this.banks = this.displayedAccounts.filter(item => { return item.account_type == AccountType.Bank; });
        this.creditCards = this.displayedAccounts.filter(item => { return item.account_type == AccountType.CreditCard; });
        this.investments = this.displayedAccounts.filter(item => { return item.account_type == AccountType.Investment; });
        switch (this.selectedTab) {
            case AccountType.Bank:
                this.displayedAccounts = this.banks;
                break;
            case AccountType.CreditCard:
                this.displayedAccounts = this.creditCards;
                break;
            case AccountType.Investment:
                this.displayedAccounts = this.investments;
                break;
        }
        this.tableState.setDataSource(this.getDataSource(), { reset: true });
    }

    getDataSource = () => {
        return new InMemoryDataSource(this.displayedAccounts, this.idSelector, {});
    }

    idSelector = (row: any) => {
        return row.Id;
    }
}