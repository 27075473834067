import {action, makeObservable, observable, runInAction } from 'mobx';
import { AccountModel, Accounts, AccountTransaction, AccountType, PlaidItemAccount, PlaidItemData, PlaidItemModel } from '../api/accounts.api';
import { InMemoryDataSource, TableState } from '@servicetitan/table';
import { FormEvent } from 'react';
import moment from 'moment';
import { TransactionModel, Transactions } from '../../transaction/api/transactions.api';
export class AccountTransactionsStore {
    @observable account: AccountModel | undefined = undefined;
    @observable tableState: TableState<any, number>;
    date = new Date();
    @observable startDate: Date | undefined = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    @observable endDate: Date | undefined = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 1);
    @observable transactions: AccountTransaction[] = [];
    @observable page: number = 0
    @observable loading: boolean = true;
    @observable showDeletedToggle: boolean = false;
    @observable showTransactions: AccountTransaction[] = [];
    accountApi: Accounts = new Accounts();
    transactionApi: Transactions = new Transactions();

    constructor() {
        makeObservable(this);
        this.tableState = new TableState<any, number, never, never>({
            pageSize: 10,
            dataSource: this.getDataSource(),
        });
    }

    @action
    getAccount = async (id: number) => {
        const account = await this.accountApi.getAccount(id);
        runInAction( () => {
            if (account.data) {
                this.account = account.data;
                this.getAccountTransactions(id);
            }
        })
    };

    @action
    getAccountTransactions = async (id: number) => {
        const accountTransactions = await this.accountApi.getAccountTransactions(id, this.startDate?.toISOString().split('T')[0], this.endDate?.toISOString().split('T')[0])
        runInAction(() => {
            if (accountTransactions.data) {
                this.transactions = accountTransactions.data;
                this.setActiveTransactions();
                this.loading = false;
            }
        })
    }

    @action
    setStartDate = (date: Date | undefined) => {
        this.startDate = date;
    };
    
    @action
    setEndDate = (date: Date | undefined) => {
        this.endDate = date;
    };

    @action showDeleted = () => {
        this.showTransactions = this.transactions;
    };

    @action showActive = () => {
        this.showTransactions = this.transactions.filter(item => { return item.is_active == true; });
    };

    @action
    toggleShowDeleted = () => {
        this.showDeletedToggle = !this.showDeletedToggle;
        this.setActiveTransactions();
    }

    @action
    deleteTransaction = async (transaction: TransactionModel) => {
        transaction.is_active = false;
        transaction.transaction_date = transaction.transaction_date?.[0];
        transaction.date_changed = transaction.date_changed?.[0];
        await this.transactionApi.updateTransaction(transaction)
        runInAction( () => {
            this.getAccountTransactions(this.account?.id || 0);
        })
    };

    @action
    restoreTransaction = async (transaction: TransactionModel) => {
        transaction.is_active = true;
        transaction.transaction_date = transaction.transaction_date?.[0];
        transaction.date_changed = transaction.date_changed?.[0];
        await this.transactionApi.updateTransaction(transaction)
        runInAction( () => {
            this.getAccountTransactions(this.account?.id || 0);
        })
    };

    setActiveTransactions = () => {
        this.showDeletedToggle ?  this.showDeleted() : this.showActive(); 
        this.tableState.setDataSource(this.getDataSource(), { reset: true });
    }

    getDataSource = () => {
        return new InMemoryDataSource(this.showTransactions, this.idSelector, {});
    }

    idSelector = (row: any) => {
        return row.Id;
    }
}