import axiosConfig from "../axiosConfig";

class AuthService {
  async login(username, password) {
    const response = await axiosConfig
          .post("auth/login", {
              username,
              password
          });
      if (response.data.access_token) {
          const userResponse = await axiosConfig
                .get("user/get/" + username)
          userResponse.data[0].access_token = response.data.access_token;
          userResponse.data[0].refresh_token = response.data.refresh_token;
          localStorage.setItem("user", JSON.stringify(userResponse.data));
      }
      return response.data;
  }

  async logout() {
    localStorage.removeItem("user");
    const response = await axiosConfig
          .post("auth/logout");
    return response.data;
  }

  async register(username, email, password, confirmPassword) {
    return await axiosConfig.post("auth/register-mobile", {
      username,
      email,
      password,
      confirmPassword
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();