import axiosConfig from "../../axiosConfig";
import authHeader from "../../services/auth-header";
import { AxiosResponse } from "axios";
import { CategoryModel } from "../../categories/api/categories.api";
import { AccountModel } from "../../accounts/api/accounts.api";

export interface ITransactions {
  getTransaction(transaction_id: number): Promise<AxiosResponse<TransactionModel>>;
  getAccounts(): Promise<AxiosResponse<AccountModel[]>>;
  getCategories(): Promise<AxiosResponse<CategoryModel[]>>;
  updateTransaction(transaction: TransactionModel): Promise<AxiosResponse<TransactionModel>>;
}

export class Transactions implements ITransactions {
    getTransaction(transaction_id: number) {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("transaction/get/" + id, { headers: authHeader(), params: {transaction_id: transaction_id}});
  }

  getAccounts() {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("accounts/" + id, { headers: authHeader() });
  }

  getCategories() {
    const id = JSON.parse(localStorage.getItem("user") || "")[0].id;
    return axiosConfig.get("categories/" + id, { headers: authHeader() });
  }

  updateTransaction(transaction: TransactionModel) {
    return axiosConfig.post(
      "transaction/update/" + transaction.id,
      {
        id: transaction.id,
        account_id: transaction.account_id,
        category_id: transaction.category_id,
        amount: transaction.amount,
        message: transaction.message,
        transfer_category_id: transaction.transfer_category_id,
        transaction_date: transaction.transaction_date,
        transaction_type_id: transaction.transaction_type_id,
        date_changed: transaction.date_changed,
        is_active: transaction.is_active,
      },
      { headers: authHeader() }
    );
  }
}

  export interface TransactionModel {
    id: number;
    message?: string | undefined;
    amount: number;
    account_id: number;
    category_id: number;
    transfer_category_id: number | undefined;
    plaid_transaction_id: number | undefined;
    is_active: boolean | undefined;
    date_changed: string | undefined;
    transaction_date: string | undefined;
    transaction_type_id: number;
  }