import { action, makeObservable, observable, runInAction } from "mobx";
import { FormEvent } from "react";
import { Login } from "../api/login.api";

export class LoginStore {
  @observable name = "";
  @observable username = "";
  @observable email = "";
  @observable password = "";
  @observable confirmPassword = "";
  @observable showForgotEmail = false;
  @observable forgotPasswordUsername = "";
  @observable showIncorrectUsernamePasswordBanner = false;
  @observable userTaken = false;
  @observable showForgotPasswordAnnouncemnt = false;
  @observable annoucementMessage = "";
  @observable annoucementSuccess = false;
  @observable resetToken = "";
  loginApi: Login = new Login();

  constructor() {
    makeObservable(this);
  }

  @action
  setName = (event: FormEvent<HTMLInputElement>) => {
    this.name = event.currentTarget.value;
  };
  
  @action
  setUsername = (event: FormEvent<HTMLInputElement>) => {
    this.username = event.currentTarget.value;
    this.showIncorrectUsernamePasswordBanner = false;
    if (this.userTaken) {
      this.checkUserTaken();
    }
  };

  @action
  setEmail = (event: FormEvent<HTMLInputElement>) => {
    this.email = event.currentTarget.value;
  };

  @action
  setPassword = (event: FormEvent<HTMLInputElement>) => {
    this.password = event.currentTarget.value;
    this.showIncorrectUsernamePasswordBanner = false;
  };

  @action
  setConfirmPassword = (event: FormEvent<HTMLInputElement>) => {
    this.confirmPassword = event.currentTarget.value;
  };

  @action
  setForgotPasswordUsername = (event: FormEvent<HTMLInputElement>) => {
    this.forgotPasswordUsername = event.currentTarget.value;
  };

  @action
  setShowForgotEmail = (show: boolean) => {
    this.showForgotEmail = show;
  };

  @action
  setShowIncorrectUsernamePasswordBanner = (show: boolean) => {
    this.showIncorrectUsernamePasswordBanner = show;
  };

  @action
  setShowForgotEmailAnnoucement = (show: boolean) => {
    this.showForgotPasswordAnnouncemnt = show;
  };

  @action
  login = async () => {
    try {
      const userTokens = await this.loginApi.login(
        this.username,
        this.password
      );
      runInAction(async () => {
        if (userTokens.data && userTokens.data.access_token) {
          const user = await this.loginApi.getUser(this.username);
          runInAction(() => {
            if (user.data && user.data.length > 0) {
              user.data[0].access_token = userTokens.data.access_token;
              user.data[0].refresh_token = userTokens.data.refresh_token;
              localStorage.setItem("user", JSON.stringify(user.data));
              window.open("/accounts", "_self");
            }
          });
        }
      });
    } catch (e) {
      this.showIncorrectUsernamePasswordBanner = true;
    }
  };

  @action
  register = async () => {
    const userTokens = await this.loginApi.register(
      this.name,
      this.username,
      this.email,
      this.password,
      this.confirmPassword
    );
    runInAction(async () => {
      if (userTokens.data && userTokens.data.access_token) {
        const user = await this.loginApi.getUser(this.username);
        runInAction(() => {
          if (user.data && user.data.length > 0) {
            user.data[0].access_token = userTokens.data.access_token;
            user.data[0].refresh_token = userTokens.data.refresh_token;
            localStorage.setItem("user", JSON.stringify(user.data));
            window.open("/accounts", "_self");
          }
        });
      }
    });
  };

  @action
  logout = async () => {
    localStorage.removeItem("user");
  };

  @action
  checkUserTaken = async () => {
    this.userTaken = false;
    const userTaken = await this.loginApi.checkUserTaken(this.username);
    runInAction(() => {
      if (userTaken.data) {
        this.userTaken = true;
      }
    });
  };

  @action
  sendForgotPasswordEmail = async () => {
    const forgotPassword = await this.loginApi.sendForgotPasswordEmail(
      this.forgotPasswordUsername
    );
    runInAction(() => {
      if (forgotPassword.data) {
        this.showForgotEmail = false;
        this.showForgotPasswordAnnouncemnt = true;
        if (forgotPassword.data.success) {
          this.annoucementSuccess = true;
          this.annoucementMessage =
            "Successfully emailed link to " + forgotPassword.data.email + "!";
        } else {
          this.annoucementMessage =
            "No user found with username " + this.forgotPasswordUsername;
        }
      }
    });
  };

  @action
  resetPassword = async (token: string) => {
    const resetPassword = await this.loginApi.resetPassword(
      this.password,
      this.confirmPassword,
      token
    );
    runInAction(async () => {
      if (resetPassword.data && resetPassword.data) {
        if (resetPassword.data.success) {
          const user = await this.loginApi.getUser(resetPassword.data.username);
          runInAction(() => {
            if (user.data && user.data.length > 0) {
              user.data[0].access_token = resetPassword.data.access_token;
              user.data[0].refresh_token = resetPassword.data.refresh_token;
              localStorage.setItem("user", JSON.stringify(user.data));
              window.open("/accounts", "_self");
            }
          });
        }
      }
    });
  };
}
