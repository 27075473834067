import {
  Avatar,
  BodyText,
  Button,
  ButtonGroup,
  Card,
  Form,
  Headline,
  Link,
  ProgressBar,
  ProgressCircle,
  Stack,
  TabGroup,
  Tag,
  TagGroup,
  ToggleSwitch,
  Tooltip,
} from "@servicetitan/design-system";
import { observer } from "mobx-react";
import { Fragment, useEffect } from "react";
import { CategoryModel, CategoryType } from "../api/categories.api";
import { CategoriesStore } from "../stores/categories.store";

const store = new CategoriesStore();

export const CategoriesComponent = observer(function CategoriesComponent() {
  useEffect(() => {
    store.getCategories();
    store.getAccounts();
  }, [store]);

  return (
    <Fragment>
      <Headline>Categories</Headline>
      {store.showTransferToggle && (
        <Card>
          <Card.Section>
            <Form>
              <Headline>Transfer</Headline>
              <BodyText subdued>
                {" "}
                Want to transfer between two categories? Select your categories,
                enter your amount, and click transfer.{" "}
              </BodyText>
              <Form.Group widths="equal">
                <Form.Input
                  label="From"
                  disabled
                  value={store.fromCategory?.category_name || ""}
                />
                <Form.Input
                  label="To"
                  disabled
                  value={store.toCategory?.category_name || ""}
                />
                <Form.Input
                  label="Amount"
                  value={store.transferAmount}
                  onChange={store.setTransferAmount}
                />
                <Form.Input
                  label="Message"
                  placeholder="Enter message"
                  value={store.transferMessage}
                  onChange={store.setTransferMessage}
                />
                <Form.DatePicker
                  label="Transaction Date"
                  value={store.transferDate}
                  onChange={(v) => store.setTransferDate(v)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Button
                  onClick={store.transfer}
                  label="Transfer"
                  color="primary"
                >
                  Transfer
                </Form.Button>
                <Form.Button
                  label="Reset"
                  onClick={() => store.resetTransferCategories()}
                >
                  Reset
                </Form.Button>
              </Form.Group>
            </Form>
          </Card.Section>
        </Card>
      )}
      <br />
        {!store.showTransferToggle && (
          <Card>
          <Headline>Budget</Headline>
          <Stack className="p-l-4">
            <ProgressCircle style={{ maxWidth: 120 }} progress={
                            (store.totalIncomeBalance /
                              store.totalBudgeted) *
                            100
                          }>
              {Math.min((store.totalIncomeBalance /
                              (store.totalBudgeted || 1)) *
                            100, 100).toFixed(2)}%
            </ProgressCircle>
            <BodyText subdued>You have</BodyText>
            <BodyText bold>
              &nbsp;$
              {store.totalBudgeted
                ?.toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
               
            </BodyText>
            <BodyText subdued>&nbsp;total budgeted across all categories. You have
              $
              {store.totalIncomeBalance
                ?.toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
              in income categories.
              </BodyText>
          </Stack>
        </Card>
        )}
      
      <br />
      <Stack>
        <TabGroup
          action={
            <Stack direction="row" spacing={2}>
              <ToggleSwitch
                small
                label="Show Deleted"
                checked={store.showDeletedToggle}
                onChange={store.toggleShowDeleted}
              />
              <ToggleSwitch
                small
                label="Transfer Mode"
                checked={store.showTransferToggle}
                onChange={store.toggleShowTransfer}
              />
              <Button xsmall primary href={"/category/" + 0}>
                Create New Category
              </Button>
            </Stack>
          }
          children={undefined}
        ></TabGroup>
      </Stack>
      <br />
      <Stack alignItems="stretch" direction="column" spacing="2">
        {store.displayCategories.map(
          (category: CategoryModel, index: number) => {
            return (
              <Stack.Item key={category.id} className="">
                <Card>
                  <Stack spacing={2}>
                    <Avatar autoColor size="s" name={category.category_name} />
                    <Stack.Item fill>
                      <Link
                        color="primary"
                        className="c-blue-400-i"
                        href={"/categories/" + category.id}
                      >
                        {category.category_name}
                      </Link>
                      &nbsp;{category.category_code}
                    </Stack.Item>
                    <Stack className="p-l-4" spacing={2}>
                      <ButtonGroup>
                      {store.showTransferToggle && (
                        <Fragment>
                        <Button
                          fill="subtle"
                          size="xsmall"
                          onClick={() => store.selectFrom(category)}
                        >
                          Select From
                        </Button>
                        <Button
                          fill="subtle"
                          size="xsmall"
                          onClick={() => store.selectTo(category)}
                        >
                          Select To
                        </Button>
                        </Fragment>
                      )}
                      </ButtonGroup>
                      <ButtonGroup>
                        <Tooltip text="Edit" portal>
                          <Button
                            xsmall
                            href={"/category/" + category.id}
                            fill="subtle"
                            iconName="edit"
                          />
                        </Tooltip>
                        {category.is_active && (
                          <Tooltip text="Delete" portal>
                            <Button
                              onClick={() => store.deleteCategory(category)}
                              xsmall
                              color="red"
                              fill="subtle"
                              iconName="delete"
                            />
                          </Tooltip>
                        )}
                        {!category.is_active && (
                          <Tooltip text="Restore" portal>
                            <Button
                              onClick={() => store.restoreCategory(category)}
                              xsmall
                              color="primary"
                              fill="subtle"
                              iconName="restore"
                            />
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </Stack>
                  </Stack>
                  <br />
                  <Stack className="p-l-4" spacing={1}>
                    <TagGroup>
                      {(category?.balance || 0) > 0 && (
                        <Tag color="green">
                          Balance $
                          {category.balance
                            ?.toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        </Tag>
                      )}
                      {((category?.balance || 0) < 0) && (
                        <Tag color="red">
                          Balance $
                          {category.balance
                            ?.toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        </Tag>
                      )}
                      {((category?.balance || 0) === 0) && (
                        <Tag>
                          Balance $
                          {category.balance
                            ?.toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        </Tag>
                      )}
                      {category?.category_type === CategoryType.Income && (
                        <Tag color="green">Income</Tag>
                      )}
                      {category?.category_type === CategoryType.Budget && (
                        <Tag color="orange">Budget</Tag>
                      )}
                      {category?.category_type === CategoryType.Goal && (
                        <Tag color="blue">Goal</Tag>
                      )}
                    </TagGroup>
                  </Stack>
                  <br />
                  <Stack className="p-l-4" spacing={5}>
                    {category.category_type === CategoryType.Budget && (
                      <div style={{ width: 400 }}>
                      <ProgressBar
                        progress={
                          ((category?.spending || 0) /
                            (category?.budget_amount || 1)) *
                          100
                        }
                      ></ProgressBar>
                      <BodyText subdued>
                        You spent $
                        {category.spending
                          ?.toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                        of $
                        {category.budget_amount
                          ?.toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                        budgeted
                      </BodyText>
                    </div>
                    )}
                    {category.category_type === CategoryType.Goal && (
                      <div style={{ width: 400 }}>
                      <ProgressBar
                        progress={
                          ((category?.balance || 0) /
                            (category?.goal_amount || 1)) *
                          100
                        }
                      ></ProgressBar>
                      <BodyText subdued>
                        You have $
                        {category.balance
                          ?.toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                        of $
                        {category.goal_amount
                          ?.toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                        saved
                      </BodyText>
                    </div>
                    )}
                    
                    
                  </Stack>
                </Card>
              </Stack.Item>
            );
          }
        )}
      </Stack>
    </Fragment>
  );
});
