import { ResponsiveHeatMap } from "@nivo/heatmap";
import React, { useState, useEffect, Fragment } from "react";
import AllocationService from "../services/allocation-service";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { lighten, makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CloseMonthService from "../services/close-month-service";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  selectAllMonthlyCategories,
  selectAllMonthlyAccounts,
  fetchAccountsMonthly,
  fetchCategoriesMonthly,
  addAllocation,
  addCloseMonth,
} from "../slices/home-slice";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  BodyText,
  Headline,
  Button,
  ButtonGroup,
  Stack,
  Card,
} from "@servicetitan/design-system";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EnhancedTableToolbar = (props) => {
  const isLoading = props.isLoading;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleAllocation = (event) => {
    dispatch(addAllocation());
  };

  const handleCloseMonth = (event) => {
    dispatch(addCloseMonth());
  };
  const handleCloseMonthClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCloseMonthAlertOpen(false);
  };

  const classes = useToolbarStyles();
  const [content, setContent] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [closeMonthAlertOpen, setCloseMonthAlertOpen] = React.useState(false);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Headline>Budget Summary</Headline>
      <BodyText>
        Want to add monthly amount to categories? Click Add Month Amount to
        Categories.
      </BodyText>
      <br />
      <Button color="primary" onClick={() => handleAllocation()}>
        Add Monthly Amount to Categories
      </Button>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Successfully finished monthly allocation!
        </Alert>
      </Snackbar>
      <Snackbar
        open={closeMonthAlertOpen}
        autoHideDuration={3000}
        onClose={handleCloseMonthClose}
      >
        <Alert onClose={handleCloseMonthClose} severity="success">
          Successfully ended the current month!
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.info.main,
          backgroundColor: lighten(theme.palette.info.light, 0.85),
        }
      : {
          color: theme.palette.text.info,
          backgroundColor: theme.palette.info.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

export default function Home() {
  // make sure parent container have a defined height when using
  // responsive component, otherwise height will be 0 and
  // no chart will be rendered.
  // website examples showcase many properties,
  // you'll often use just a few of them.
  //const [categoryMonthlyData, setCategoryMonthlyData] = useState([]);
  //const [accountMonthlyData, setAccountMonthlyData] = useState([]);
  const dispatch = useDispatch();
  const categoryMonthlyData = useSelector(selectAllMonthlyCategories);
  const accountMonthlyData = useSelector(selectAllMonthlyAccounts);
  const homeStatus = useSelector((state) => state.home.status);

  useEffect(() => {
    if (
      homeStatus === "idle" ||
      homeStatus == "allocated" ||
      homeStatus == "closed month"
    ) {
      dispatch(fetchCategoriesMonthly());
    }
  }, [homeStatus, dispatch]);

  if (homeStatus == "category success") {
    dispatch(fetchAccountsMonthly());
  }

  const hasAccountsFetched = homeStatus == "succeeded";

  const hasCategoriesFetched = homeStatus == "succeeded";

  const isLoading = homeStatus == ("loading" || "category success");

  const AccountsHeatMap = () => (
    <ResponsiveHeatMap
      data={accountMonthlyData}
      keys={[
        "current",
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ]}
      indexBy="account_name"
      margin={{ top: 30, right: 100, bottom: 30, left: 150 }}
      sizeVariation={0}
      axisTop={{
        orient: "top",
        tickSize: 7,
        tickPadding: 7,
        legendPosition: "middle",
        tickRotation: -1,
        legend: "",
        legendOffset: -50,
      }}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -90,
      }}
      cellOpacity={1}
      cellBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.8]] }}
      defs={[
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(0, 0, 0, 0.1)",
          rotation: -45,
          lineWidth: 4,
          spacing: 7,
        },
      ]}
      fill={[{ id: "lines" }]}
      animate={true}
      motionConfig="wobbly"
      motionStiffness={0}
      motionDamping={9}
      hoverTarget="cell"
      cellShape="circle"
      maxValue={15}
      minValue={-15}
      cellHoverOthersOpacity={1}
    />
  );

  const CategoriesHeatMap = () => (
    <ResponsiveHeatMap
      data={categoryMonthlyData}
      keys={[
        "current",
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ]}
      indexBy="category_name"
      margin={{ top: 30, right: 60, bottom: 30, left: 200 }}
      sizeVariation={0}
      axisTop={{
        orient: "top",
        tickSize: 7,
        tickPadding: 7,
        legendPosition: "middle",
        tickRotation: -1,
        legend: "",
        legendOffset: -50,
      }}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -90,
      }}
      cellOpacity={1}
      cellBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.8]] }}
      defs={[
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(0, 0, 0, 0.1)",
          rotation: -45,
          lineWidth: 6,
          spacing: 7,
        },
      ]}
      fill={[{ id: "lines" }]}
      animate={true}
      motionConfig="gentle"
      motionStiffness={0}
      motionDamping={0}
      hoverTarget="cell"
      maxValue={50}
      minValue={-50}
      cellHoverOthersOpacity={0.25}
      forceSquare={false}
    />
  );
  return (
    <div>
      <EnhancedTableToolbar></EnhancedTableToolbar>
      <br />
      <br />
      <Card>
        <Headline>Accounts</Headline>
        <div style={{ height: 550 }}>
          {hasAccountsFetched && <AccountsHeatMap></AccountsHeatMap>}
        </div>
      </Card>
      <br />

      <Card>
        <Headline>Categories</Headline>
        {hasCategoriesFetched && (
          <div style={{ height: 800 }}>
            <CategoriesHeatMap></CategoriesHeatMap>
          </div>
        )}
      </Card>
    </div>
  );
}
