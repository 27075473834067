import axiosConfig from "../axiosConfig";
import authHeader from './auth-header';

class AllocationService {
  postAllocation() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('category/budget/' + id, "", { headers: authHeader() });
  };
}

export default new AllocationService();