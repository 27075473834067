import React, { useCallback, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { selectLinkToken, generateLinkToken, fetchLink, exchangePublicAccess, selectAllItems, selectPlaidStatus, fetchAllItems, selectPlaidPublicToken, resetAccessToken, updateAccessToken, selectItemTransactions, fetchItemTransactions, fetchInstitutionImage, selectInstitutionImage, updateItem } from '../slices/plaid-slice'
import EditIcon from '@material-ui/icons/Edit';
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import plaidService from '../services/plaid-service';
import { List, ListItem, Grid, Paper, makeStyles, Typography, IconButton, TextField, CardHeader, Divider } from '@material-ui/core';
import { BodyText, Headline, Button, ButtonGroup, Stack, Card, Link, Tag } from '@servicetitan/design-system';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  spinner: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "2em",
  },
}));

const PlaidLink = () => {
  const dispatch = useDispatch();
  const linkToken = useSelector(selectLinkToken);
  const items = useSelector(selectAllItems)
  const itemTransactions = useSelector(selectItemTransactions)
  const plaidStatus = useSelector(selectPlaidStatus)
  const publicToken = useSelector(selectPlaidPublicToken)
  const institutionData = useSelector(selectInstitutionImage)
  const [selectedAccessToken, setSelectedAccessToken] = useState('')
  let imageSrc = "data:image/jpeg;base64, "
  if (Object.keys(institutionData).length !== 0) {
    imageSrc += institutionData.logo
  }
  const [meta, setMeta] = useState<PlaidLinkOnSuccessMetadata>()
  const classes = useStyles();

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    console.log(publicToken, metadata);
    setMeta(metadata)
    await dispatch(
      exchangePublicAccess({publicToken: publicToken, metaData: metadata})
   );
  }, []);

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);
  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata);
  }, []);

  const resetAccess = async(accessToken: string) => {
    await dispatch(resetAccessToken({accessToken: accessToken}))
  }

  const updateAccess = async(accessToken: string) => {
    setSelectedAccessToken(accessToken)
    await dispatch(updateAccessToken({accessToken: accessToken}))
  }

  const resetTokens = async() => {
    setSelectedAccessToken('')
    await dispatch(generateLinkToken())
  }

  const fetchItemTrans = async(accessToken: string) => {
    await dispatch(fetchItemTransactions({accessToken: accessToken}))
  }

  const fetchInstitutionImg = async(institutionId: string) => {
    await dispatch(fetchInstitutionImage({institutionId: institutionId}))
  }

  const deleteItem = async(itemId: number) => {
    await dispatch(updateItem({itemId: itemId, active: false}))
  }

  const restoreItem = async(itemId: number) => {
    await dispatch(updateItem({itemId: itemId, active: true}))
  }

  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess,
    onEvent,
    onExit,
  };
  const {
    
    open,
    ready,
    // error,
    // exit
  } = usePlaidLink(config);

  // get a link_token from your API when component mounts
  React.useEffect(() => {
    if (plaidStatus === 'idle') {
      dispatch(fetchAllItems())
      dispatch(generateLinkToken())
    }
  }, [plaidStatus, dispatch]);

  if (plaidStatus == "retry" && publicToken && meta){
    onSuccess(publicToken, meta)
  }

  return (

    <Fragment>
    <Headline>Account and Credit Card Linking</Headline>
    <BodyText>Want to link banks to pull transactions from statements? Click open.</BodyText>
    <br />
    <ButtonGroup>
      <Button onClick={() => resetTokens()} color="primary">Reset</Button>
      <Button onClick={() => open()} disabled={!ready || plaidStatus == 'update link'}>Open New Account</Button>
    </ButtonGroup>
    <br />
    {plaidStatus == 'update link' && (
      <Tag color="success">Update Mode</Tag>
    )}
    
    <br />
    
    <Stack direction="column" alignItems="flex-start" spacing={2} >
          {items.map((value: any, index: any) => (
            <Card key={index} item  xl="auto">
              <Headline >
                  {value.institution_name}
                </Headline>
                <ButtonGroup>

                <Button onClick={() => open()} disabled={!ready || value.access_token != selectedAccessToken}>Open Account to Update</Button>
                <Button color="primary" onClick={() => updateAccess(value.access_token)}>Update</Button>
                <Button onClick={() => fetchItemTrans(value.access_token)}>Show 30 Days of Transactions</Button>
                {value.is_active && (
                  <Button color="red" onClick={() => deleteItem(value.id)}>Delete</Button>
                )}
                {!value.is_active && (
                  <Button color="primary" onClick={() => restoreItem(value.id) }>Restore</Button>
                )}
                
                </ButtonGroup>
                
            </Card>
          ))}
    </Stack>
    <br />
    {itemTransactions && itemTransactions.length > 0 && (
      <Fragment>
      <Headline>Transactions</Headline>
      <BodyText>Here is the past 30 days of transactions for your bank.</BodyText>
      <br />
      <Stack direction='column'>
        {itemTransactions.map((value: any, index: any) => {
          return (
            <Stack.Item>
              <Card>
             ${Number(value.amount)?.toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} - {value.name}
            </Card>
            <br />
            </Stack.Item>
            
          )
        }

)}
      </Stack>
      </Fragment>
    )}
    {Object.keys(institutionData).length !== 0 && (
      <img src={imageSrc}></img>
    )}
      
    </Fragment>
  )
};

export default PlaidLink;