import axiosConfig from "../axiosConfig";
import authHeader from './auth-header';

class UserService {
  
  getUserBoard() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('user/getbyid/' + id, { headers: authHeader() });
  }

  getUserPermissions() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('user/get_permissions/' + id, { headers: authHeader() });
  }
}

export default new UserService();