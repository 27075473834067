import axiosConfig from "../axiosConfig";
import authHeader from './auth-header';

class CategoryTransactionsService {

  getCategoryTransactions(id, startDate, endDate) {
    return axiosConfig.get('category/' + id, { headers: authHeader(), params: {start: startDate, end: endDate} });
  };

  getCategoryAllocationTransactions(id) {
    return axiosConfig.get('category/allocations/' + id, { headers: authHeader() });
  };

  postCategoryTransactions(id, rows) {
    return axiosConfig.post('category/' + id, rows, { headers: authHeader() });
  };
}

export default new CategoryTransactionsService();