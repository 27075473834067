// First we need to import axios.js
import axios from "axios";
// Next we make an 'instance' of it
const axiosInstance = axios.create({
  // .. where we make our configurations
  //baseURL: "http://127.0.0.1:5000/api/",
  baseURL: 'https://lint-dev.com/api/'
});

// Function to get the refresh token and update access token
async function refreshAccessToken() {
  try {
    const user = JSON.parse(localStorage.getItem("user"))[0];

    if (user && user.refresh_token) {
      const response = await axiosInstance.post(
        "/auth/refresh",
        {
          device_id: "web",
        },
        { headers: { Authorization: "Bearer " + user.refresh_token } }
      );

      // Save the new access token
      user.access_token = response.data.access_token;
      localStorage.setItem("user", JSON.stringify([user]));
      return response.data.access_token;
    }
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    // Handle token refresh failure (e.g., log out the user)
    throw error;
  }
}

// Axios response interceptor for refreshing the token on 401 error
axiosInstance.interceptors.response.use(
  (response) => response, // Return the response if it's successful
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token (401)
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest.url.includes("auth")
    ) {
      originalRequest._retry = true; // Avoid retrying endlessly

      try {
        // Attempt to refresh the access token
        const newAccessToken = await refreshAccessToken();

        // Update the original request's Authorization header with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request with the new token
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Handle refresh token failure (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
