import {
  BodyText,
  Card,
  Headline,
} from "@servicetitan/design-system/dist/components";
import { Fragment } from "react";

export const TermsOfServiceComponent = () => {
  return (
    <Fragment>
      <Headline>Terms of Service</Headline>
      <Headline size="small">Effective Date: May 31, 2024</Headline>
      <BodyText class="p-5-y">
        <br />
        Welcome to Lint Finance These Terms of Use (“Terms”) are a legal
        agreement between you and Lint Finance, a Washington LLC (“Lint,” “we,”
        “our,” or “us”), and govern your use of the Lint mobile application (the
        “App”), and website (the “Site”) and all content, services and products
        available at or through the App and Site (collectively, the “Services”).
        Lint is the owner of the Services, including without limitation, the App
        and the Site.
        <br />
        <br />
        “You," “your,” and similar terms refer to users and potential users
        (collectively, “Users”) who access or use the App, the Site and/or the
        Services. Your compliance with these Terms is a condition to your use of
        the App, the Site and/or Services. If you do not or cannot comply with
        these Terms you are not permitted to use the App, the Site or the
        Services.
        <br />
        <br />
        Please read these Terms carefully before accessing or using our
        Services. By accessing or using any part of our Services, you agree to
        become bound by these Terms. If you do not agree to all of these Terms,
        then you must immediately discontinue your use of the App, the Site and
        the Services. If these Terms are considered an offer by Lint, acceptance
        is expressly limited to these Terms. THESE TERMS CONTAIN A DISPUTE
        RESOLUTION AND ARBITRATION PROVISION, INCLUDING A CLASS ACTION WAIVER
        THAT AFFECTS YOUR RIGHTS UNDER THESE TERMS AND WITH RESPECT TO DISPUTES
        YOU MAY HAVE WITH Lint.
        <br />
        <br />
        If you are entering into these Terms on behalf of a company,
        organization or other business entity, you hereby represent and warrant
        that you are authorized to enter into these Terms on behalf of such
        company, organization or other business entity.
      </BodyText>
      <Headline size="small">Scope and Authorization</Headline>
      <Card class="p-b-2">
        The Services include a financial information management tool, which
        enables Users to track and organize their finances. This includes
        financial account information (“User Data”) that is aggregated from
        financial institutions and other third-party data sources that are
        selected by Users and with whom the User has an independent contractual
        relationship (“Third Party Data Sources”). In addition, the Services may
        include a social functionality that will enable Users to share their
        financial metrics (e.g., total amount spent on “Transportation”
        category) with other individuals outside of the Lint platform. When
        using the features of the App and the Site you are subject to these
        Terms as well as any other applicable agreements, policies or guidelines
        applicable to such features that may be posted from time to time,
        including thePrivacy Policy. All such agreements, policies or guidelines
        are hereby incorporated by reference into these Terms. By accessing and
        using the Services, you authorize and direct Lint, as your agent and on
        your behalf, to electronically retrieve your User Data from Third-Party
        Data Sources. Subject to the Lint Privacy Policy, Lint may work with one
        or more third-party financial service technology providers to access and
        retrieve your User Data. FOR PURPOSES OF THIS AGREEMENT AND SOLELY TO
        OBTAIN AND PROVIDE THE USER DATA TO YOU AS PART OF THE SERVICES, YOU
        GRANT LINT A LIMITED POWER OF ATTORNEY, AND APPOINT LINT AS YOUR
        ATTORNEY-IN-FACT AND AGENT WITH FULL POWER OF SUBSTITUTION AND
        RE-SUBSTITUTION, FOR YOU AND IN YOUR NAME, PLACE AND STEAD, IN ALL
        CAPACITIES, TO ACCESS SITES, SERVERS OR DOCUMENTS RELATED TO OR
        MAINTAINED BY THE THIRD-PARTY DATA SOURCES, RETRIEVE INFORMATION, AND
        USE YOUR INFORMATION WITH THE FULL POWER AND AUTHORITY TO PERFORM EVERY
        ACT AND THING REQUISITE AND NECESSARY TO BE DONE IN CONNECTION WITH SUCH
        ACTIVITIES, AS YOU COULD DO IN PERSON. YOU ACKNOWLEDGE AND AGREE THAT
        WHEN LINT IS ACCESSING AND RETRIEVING USER DATA FROM THIRD-PARTY DATA
        SOURCES, LINT IS ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON
        BEHALF OF ANY THIRD PARTY. YOU AGREE THAT THIRD-PARTY DATA SOURCES WILL
        BE ENTITLED TO RELY ON THE FOREGOING AUTHORIZATION, AGENCY AND POWER OF
        ATTORNEY GRANTED BY YOU. You understand that the Services are not
        endorsed or sponsored by any Third-Party Data Sources accessible through
        the Services.
      </Card>
      <Headline size="small">Permitted Use</Headline>
      <Card class="p-b-2">
        In order to register as a User, provide Personal Information (as defined
        in the Privacy Policyy) to us, or otherwise use our Services, App or
        Site, you hereby represent that:
        <br />
        1. You are at least thirteen (13) years of age or older. Furthermore, if
        you are less than eighteen (18) years of age, you represent that you
        have obtained consent from your parent or legal guardian to use the
        Services, App and Site. If you are (i) younger than thirteen (13) years
        of age, or (ii) younger than eighteen (18) years of age and have not
        obtained the necessary consent from your parent or legal guardian, you
        may not register for or use our Services.
        <br />
        2. You agree to these Terms.
        <br />
        3. Subject to these Terms, we grant you a limited, personal,
        non-exclusive and non-transferable license to use our Services and to
        access and use the App and Site (“Permitted Use”). All intellectual
        property, ownership and other rights of any kind whatsoever in the App,
        the Site and Services remain our property and are exclusively owned by
        Lint.
      </Card>
      <Headline size="small">Unauthorized Activities</Headline>
      <Card class="p-b-2">
        Any other use of the Services, App or Site beyond the Permitted Use is
        prohibited and constitutes unauthorized use of the Services, App and/or
        Site, as applicable.
        <br />
        <br />
        Unless you have written permission from us stating otherwise, you are
        not authorized to use the Services, App or Site in any of the following
        ways. Note that these are examples only, and the list below is not a
        complete list of everything that you are not permitted to do.
        <br />
        <br />
        You are not allowed to use the Services, App or Site:
        <br />
        <li>
          In a manner that modifies, publicly displays, publicly performs,
          reproduces, or distributes any of the Services, the App or Site;
        </li>
        <li>In a manner that violates any relevant law or these Terms;</li>
        <li>
          To stalk, harass, spam, or harm another individual, organization, or
          business;
        </li>
        <li>
          To disclose information that you do not have permission to disclose;
        </li>
        <li>
          To impersonate any person or entity, misstate your Personal
          Information, or otherwise misrepresent your affiliation with a person
          or entity;
        </li>
        <li>
          {" "}
          To interfere with or disrupt the Services the App or the Site or
          servers or networks connected to the Services, the App or the Site;
        </li>
        <li>
          To use any data mining, robots, or similar data gathering or
          extraction methods in connection with the Services, the App or the
          Site;
        </li>
        <li>To disseminate any viruses, worms, or otherwise harmful code;</li>
        <li>
          To attempt to gain unauthorized access to any portion of the Services,
          the App or the Site or any other accounts, computer systems, or
          networks connected to the Services, the App or the Site, whether
          through hacking, password mining, or any other means;
        </li>
        <li>
          To disclose any types of information listed in California Customer
          Records statute (Cal. Civ. Code § 1798.80(e)) or other relevant
          privacy laws or regulations, including medical or health insurance
          information, education information, employment information, credit or
          debit card information, or any other financial information, driver’s
          license, or other government identification number;{" "}
        </li>
        <li>
          To disclose or collect any types of information considered to be
          protected classification characteristics under California or other
          relevant law, including age (if 40 years or older), race, color,
          ancestry, national origin, citizenship, religion or creed, marital
          status, medical condition, physical or mental disability, sex
          (including gender, gender identity, gender expression, pregnancy or
          childbirth, and related medical conditions), sexual orientation,
          veteran or military status, and genetic information (including
          familial genetic information);
        </li>
        <li>
          To disclose or collect any information defined by relevant laws as
          biometric information, or any information that falls within the
          definition of “special categories of data” under EU Data Protection
          Laws, “protected health information” under California laws, or any
          other relevant law or regulations. “Special categories of data”
          include (but are not limited to) the following information about a
          person:
        </li>
        <li>Race</li>
        <li>Ethnic origin</li>
        <li>Sexual orientation and sex life</li>
        <li>Political and trade union membership</li>
        <li>Criminal records</li>
        <li>Health, genetics, and biometric</li>
        <li>To sell illegal goods or services</li>
        <li>To distribute pornography/sexually explicit content</li>
        <li>
          to promote or support terrorism or violent extremism; · In a way that
          violates any United States law or regulation, or, if located outside
          the United States, in a way that violates any law or regulation of
          such location or country; or
        </li>
        <li>
          Use or access the App, Site or Services to create a competing product
          or service.
        </li>
        <br />
        In addition to the above, Lint reserves the right to terminate your
        account at any time at its discretion and without notice. Unauthorized
        use of the Services, the App or the Site may result in the violation of
        various United States and international laws, including copyright laws,
        and may further result in the deletion of your Lint account or a block
        from the Services, the App and Site.
      </Card>
      <Headline size="small">Accounts</Headline>
      <Card class="p-b-2">
        You may sign up for an account through the App or on the Site. Signing
        up for an account is free; however, we may also offer various paid
        subscriptions, premium access or fee-based services. We reserve the
        right to terminate any accounts that have been inactive for over 8
        months. Furthermore, we reserve the right to determine whether or not to
        approve your proposed account based on our discretion.
        <br />
        <br />
        As part of the account set up process, we will ask you for your
        information, including phone number, username and email address.
        <br />
        <br />
        You agree to provide us with accurate, true, and complete information.
        Should your information change, you agree to keep it up-to-date. You
        agree to choose a h3 and secure password(s) and maintain the
        confidentiality of your password(s), including any password of a
        third-party site that we may allow you to use to access the App or the
        Site. Should you believe your password or security for the App or the
        Site has been breached in any way, you must immediately notify us at
        dolsenj@umich.edu.
        <br />
        <br />
        Lint or our billing processors collect your billing information if and
        when you upgrade to a paid account or access any fee-based services. All
        information is protected in accordance with our Privacy Policy.
      </Card>
      <Headline size="small">
        Subscription Period and Subscription Fees
      </Headline>
      <Card class="p-b-2">
        Lint may offer a free trial period during which you can use the Services
        for a limited period of time at no cost. You can manage or cancel the
        trial subscription through your app store account or otherwise via any
        methods described in the Service.
        <br />
        <br />
        If you subscribe to any paid subscriptions, you agree to pay all
        applicable fees related to such subscriptions. By providing a payment
        method, you expressly authorize us to charge the applicable fees on said
        payment method as well as taxes and other charges incurred thereto at
        regular intervals, all of which depend on your particular subscription
        and utilized Services.
        <br />
        <br />
        The subscription period for paid accounts can be month-to-month, yearly,
        or another duration described more fully on the Site and the App.
        <br />
        <br />
        Paid accounts are subject to fees based upon the account type you have
        selected. All subscriptions will automatically renew unless they are
        cancelled through your app store account or otherwise as specified in
        these Terms. For such renewals, Lint will automatically charge your
        payment method in the amount of the then-current fee for your type of
        account, plus applicable taxes. All payments are non-refundable. To the
        extent that payment mechanisms are provided through third parties, you
        agree that Lint will have no liability to you arising out of the acts or
        omissions of such third parties. If applicable, you agree to maintain
        current billing information.
        <br />
        <br />
        We may change our fees at any time. When applicable, we may give you
        advance notice of the fee changes.
        <br />
        <br />
        BY CREATING A PAID ACCOUNT, YOU CONSENT TO ALLOW LINT TO CHARGE YOUR
        PAYMENT METHOD, EITHER DIRECTLY OR THROUGH ITS PAYMENT PROCESSORS, FOR
        THE AMOUNTS DUE FOR YOUR INITIAL SUBSCRIPTION PERIOD AND FOR ADDITIONAL
        SUBSCRIPTION PERIODS UNTIL YOUR ACCOUNT IS CANCELED OR TERMINATED.
      </Card>
      <Headline size="small">Renewal and Cancellation</Headline>
      <Card class="p-b-2">
        If Lint chooses to use paid accounts in the future, those accounts may
        automatically renew for the same subscription period unless you cancel
        the account by the end of the then-current subscription period by
        modifying your subscription preferences in your app store account. Any
        paid subscriptions that are purchased through an app store will be
        managed directly by the app store (e.g., Apple), and Lint will not be
        able to handle modifications or cancellations of those accounts. You
        will be able to manage the subscription on your mobile device through
        the appropriate account settings.
        <br />
        <br />
        You can also cancel your account at any time by contacting customer
        service at dolsenj@umich.edu. It may take up to five business days for
        the update or cancellation to take effect.
        <br />
        <br />
        Please note that after you cancel your account via any method, you may
        not be able to use or access the platform or our Services and you will
        not be entitled to a refund of any fees that you have already paid.
      </Card>
      <Headline size="small">Legal Compliance</Headline>
      <Card class="p-b-2">
        You agree to comply with all applicable domestic and international laws,
        statutes, ordinances, and regulations regarding your use of our
        Services, the App and the Site. Lint reserves the right to investigate
        complaints or reported violations of our Terms and to take any action we
        deem appropriate, including but not limited to canceling your User
        account, reporting any suspected unlawful activity to law enforcement
        officials, regulators, or other third parties and disclosing any
        information necessary or appropriate to such persons or entities
        relating to your profile, e-mail addresses, usage history, posted
        materials, IP addresses, and traffic information, as allowed under
        ourPrivacy Policy. You agree to promptly notify Lint in the event that
        you are made aware of any unauthorized or illegal use of the Services,
        the App or the Site.
      </Card>
      <Headline size="small">Intellectual Property and Feedback</Headline>
      <Card class="p-b-2">
        Our Services, the App and the Site may contain our copyrights, service
        marks, trademarks or other intellectual property, as well as those of
        our affiliates or other companies, in the form of words, graphics, and
        logos (collectively, “Intellectual Property”). Your use of our Services
        and/or the App and the Site does not constitute any right or license for
        you to use such Intellectual Property. The copying, redistribution, use,
        or publication by you of any portion of our Services, the App or the
        Site (without written permission from us) is strictly prohibited. Your
        use of our Services, the App and/or the Site does not grant you
        ownership rights of any kind in such Services, the App or the Site. Your
        use of our Services grants you no right or license to reproduce or
        otherwise use any Intellectual Property belonging to Lint, its
        affiliates, or any third-party partners or service providers.
        <br />
        <br />
        If you provide us with any suggestions for improvement, comments, or
        other feedback regarding the Services, the App or the Site
        (collectively, “Feedback”), you hereby grant to Lint a non-exclusive,
        worldwide, perpetual, irrevocable, fully-paid, royalty-free,
        sublicensable and transferable license under any and all intellectual
        property rights that you own or control to use, copy, modify, create
        derivative works based upon and otherwise exploit the Feedback for any
        purpose.
      </Card>
      <Headline size="small">Notices and Messages</Headline>
      <Card class="p-b-2">
        By using the Services, the App and/or the Site, you consent to receive
        emails from us. These emails may include notices about applicable fees
        and charges, transactional information and other information concerning
        or related to the Services, the App or the Site. These emails are part
        of your relationship with us.
        <br />
        <br />
        By signing up for any paid accounts or any fee-based services, you
        consent to receive emails and other alerts related to such accounts
        and/or services (e.g., confirmations and reminders). Furthermore, when
        you sign up for a Lint account, you consent to receive automatic and
        voluntary “push” notifications based on the preferences you select
        (collectively, “Notifications”).
        <br />
        <br />
        If you do not wish to receive Notifications, you may disable them;
        provided, however, that you understand and acknowledge that some
        Notifications are necessary for the proper functioning of the Services.
        <br />
        <br />
        If you provide your phone number for text reminders, you also consent to
        receive SMS text reminders from Lint related to the accounts and/or
        services that you have signed up for.
        <br />
        <br />
        You agree that any notices, agreements, disclosures, or other
        communications that we send you electronically will satisfy any legal
        communication requirements, including that such communications be in
        writing. You agree to keep your contact information up to date, and to
        monitor your email settings to ensure that you are able to receive
        emails from us.
      </Card>
      <Headline size="small">Third Party Websites and Integrations</Headline>
      <Card class="p-b-2">
        Our App and/or the Site may contain links to third party websites. These
        links are provided solely as a convenience to you. By linking to these
        websites, we do not create or have an affiliation with or sponsor such
        third-party websites. The inclusion of links within our App or the Site
        does not constitute any endorsement, guarantee, warranty, or
        recommendation of such third-party websites. Lint has no control over
        the legal documents and privacy practices of third-party websites, and
        by using them, you may be giving those third parties permission to use
        or control your Personal Information in ways Lint would not. As such,
        you are solely responsible for your decision to access any third-party
        websites and you do so at your own risk.
        <br />
        <br />
        The Services may allow you to connect or integrate your Lint account
        with certain accounts provided by third parties with whom you have a
        customer relationship, maintain an account, or engage in financial
        transactions (“Third-Party Integrations”). Lint may also use third party
        data sources to assist in facilitating Third-Party Integrations and
        collect and sync data from Third-Party Integrations (“Third-Party
        Integration Data”). Lint does not review Third-Party Integration Data
        for accuracy, legality or non-infringement. Lint is not responsible for
        Third-Party Integration Data or products and services offered by or on
        third-party sites. Lint cannot always foresee or anticipate technical or
        other difficulties which may result in failure to obtain data or loss of
        data, and personalization settings, or from device operating environment
        malfunctions or other service interruptions. Lint cannot assume
        responsibility for the timeliness, accuracy, deletion, non-delivery or
        failure to store any User Data, communications or personalization
        settings. You are responsible for connecting, integrating, and syncing
        with Third-Party Integrations, including any necessary credentials,
        authentication codes, keys, or other tools to connect with a Third-Party
        Integration. We may provide tools to facilitate such connections and/or
        guides detailing a process for facilitating such connections, but you
        are entirely responsible for arranging such connections in an accurate,
        complete, and secure manner. You agree that Lint disclaims any
        responsibility or liability for and that Lint will not be held liable
        for any loss arising out of relating to your connection to a Third-Party
        Integration, and you hereby irrevocably waive any claim against Lint
        with respect to any Third-Party Integrations or Third-Party Data.
      </Card>
      <Headline size="small">User Submissions</Headline>
      <Card class="p-b-2">
        The Services may enable you to send, transmit or post information,
        opinions, creative suggestions, ideas, notes, concepts, or other
        materials (each, a “Submission”). By posting such Submissions to any
        area of the Service, you grant Lint Finance, Inc. and its designees a
        worldwide, non-exclusive, sublicensable (through multiple tiers),
        assignable, royalty-free, perpetual, irrevocable license to use,
        reproduce, distribute (through multiple tiers), create derivative works
        of, publicly perform, publicly display, digitally perform, make, have
        made, sell, offer for sale, and import such Submissions in any media now
        known or hereafter developed, for the purpose of providing, enhancing,
        and developing the Service, without compensation to you. We will never
        use your name in connection with any of your Submissions that we use in
        our own advertising and marketing materials without obtaining your prior
        consent. You hereby waive any moral rights or other rights with respect
        to attribution of authorship regarding Submissions that you may have
        under applicable law. None of the Submissions will be subject to any
        obligation, whether of confidentiality, attribution, or otherwise, on
        our part and we will not be liable for any use or disclosure of any
        Submissions. We assume no responsibility for the information in the
        Submissions. We do not endorse the information contained in the
        Submissions or guarantee its correctness. If you choose to rely on
        another user’s Submission, you are solely responsible for such decision
        and you do so at your own risk. You, and you alone, are responsible for
        making sure that your Submissions meet the following requirements:
        <li>
          Your Submissions do not contain any information that may be considered
          sensitive (including Personal Information, passwords, bank account
          details), or confidential information.
        </li>
        <li>
          Your Submissions do not infringe, violate, or misappropriate any
          third-party right, including any copyright, trademark, patent, trade
          secret, moral right, privacy right, right of publicity, or any other
          intellectual property or proprietary right or any right of
          confidentiality of any other person or entity.
        </li>
        <li>Your Submissions do not violate any laws or regulations.</li>
        <li>
          Your Submissions do not infringe on the rights of others or slander,
          defame or libel any other person or entity.
        </li>
        <li>
          Your Submissions do not contain language that may be misleading or
          offensive to others.
        </li>
        <li>
          Your Submissions do not contain any viruses, adware, spyware, worms,
          or other harmful or malicious code.
        </li>
        <br />
        We reserve the right not to publish a Submission, or to remove a
        published one, at our own discretion and without notice. We reserve the
        right, but not the obligation, to review and approve any Submission
        before publishing it.
        <br />
        <br />
        We do not guarantee you will be able to edit or remove a Submission. We
        may provide functionality within the App or the Site to allow you to
        edit or remove your Submissions. You may also contact dolsenj@umich.edu
        to request removal of your Submission. If we are unable to remove your
        Submission, we will let you know.
      </Card>
      <Headline size="small">Access to the App, Site and Services</Headline>
      <Card class="p-b-2">
        You shall be responsible for obtaining, maintaining, and paying related
        charges for all equipment and services you need to access and use the
        App, the Site and the Services.
      </Card>
      <Headline size="small">Indemnification</Headline>
      <Card class="p-b-2">
        You alone are responsible for any violation of these Terms by you. You
        agree to indemnify, defend and hold Lint, and its parents, subsidiaries,
        affiliates, licensors and partners, as applicable, and each of their
        respective officers, agents and employees, harmless from any claim,
        demand, loss, or damages, including reasonable attorneys’ fees, arising
        out of or related to (i) your Personal Information, or the use thereof,
        including but not limited to allegations that any processing of Personal
        Information by Lint and/or you under these Terms violates any applicable
        law or regulation or infringes the privacy or intellectual property
        rights of a third party, (ii) any User Data or other content that you
        disclose to Lint via your use of the Services, the App or the Site,
        (iii) your use of or reliance on any Third-Party Integrations or
        Third-Party Data or on the user data or other content of any other User,
        (iv) your use of the Services, the App and/or the Site or the use or
        access of the foregoing by anyone using your username and password, (v)
        your use of or access to any third party software or service utilized in
        connection with the Services, the App or the Site, (vi) your violation
        of these Terms or any relevant local, state, federal or other laws,
        rules or regulations of any kind whatsoever, (vii) any infringement by
        you, or any third party using your account or acting on your behalf, of
        any intellectual property, property, privacy, or other right of any
        person or entity, or (viii) any intentional misconduct or negligence by
        you in using the Services, the App or the Site, or any third party
        software or service utilized in connection with the Services, the App or
        the Site.
      </Card>
      <Headline size="small">Intellectual Property Infingement</Headline>
      <Card class="p-b-2">
        We take intellectual property rights seriously. If we suspect a User has
        violated the intellectual property rights of others, by uploading
        materials or otherwise, we reserve the right to remove that content, and
        any other content uploaded by that User. We may also revoke the User’s
        access to the App, the Site and the Service, terminate their account, or
        utilize any other legal or other remedies available to us.
        <br />
        <br />
        Pursuant to Title 17 of the United States Code, Section 512, part of the
        Digital Millennium Copyright Act (DMCA), we have implemented procedures
        for receiving written notification of claimed copyright infringement and
        for processing such claims in accordance with such law. If you believe
        your copyright or other intellectual property right is being infringed
        by an Lint User, please provide written notice to us for notice of
        claims of infringement at dolsenj@umich.edu.
        <br />
        <br />
        To be sure the matter is handled immediately, your written notice must:
        <li>Contain your physical or electronic signature;</li>
        <li>
          Identify the copyrighted work or other intellectual property alleged
          to have been infringed;
        </li>
        <li>
          Identify the allegedly infringing material in a sufficiently precise
          manner to allow us to locate that material;
        </li>
        <li>
          Contain adequate information by which we can contact you (including
          postal address, telephone number, and e-mail address);
        </li>
        <li>
          Contain a statement that you have a good faith belief that use of the
          copyrighted material or other intellectual property is not authorized
          by the owner, the owner’s agent or the law;
        </li>
        <li>
          Contain a statement that the information in the written notice is
          accurate; and
        </li>
        <li>
          Contain a statement, under penalty of perjury, that you are authorized
          to act on behalf of the copyright or other intellectual property right
          owner.
        </li>
        <br />
        Unless the notice pertains to copyright or other intellectual property
        infringement, our agent will be unable to address the listed concern. We
        will notify you that we have removed or disabled access to
        copyright-protected material that you provided, if such removal is
        pursuant to a validly received DMCA take-down notice. In response, you
        may provide our agent with a written counter-notification that includes
        the following information:
        <br />
        1. Your physical or electronic signature;
        <br />
        2. Identification of the material that has been removed or to which
        access has been disabled, and the location at which the material
        appeared before it was removed or access to it was disabled;
        <br />
        3. A statement from you under the penalty of perjury, that you have a
        good faith belief that the material was removed or disabled as a result
        of a mistake or misidentification of the material to be removed or
        disabled; and
        <br />
        4. Your name, physical address, and telephone number, and a statement
        that you consent to the jurisdiction of a court for the judicial
        district in which your physical address is located, or if your physical
        address is outside of the United States, for any judicial district in
        which we may be located, and that you will accept service of process
        from the person who provided notification of allegedly infringing
        material or an agent of such person.
        <br />
        5. We reserve the right, in our sole discretion, to terminate the
        account or access of any Lint User who is the subject of repeated DMCA
        or other infringement notifications.
      </Card>
      <Headline size="small">Disclaimers and Third Party Information</Headline>
      <Card class="p-b-2">
        THE SERVICES, APP AND SITE AND ALL CONTENT THEREIN ARE PROVIDED “AS IS”
        AND “WITH ALL FAULTS” AND THE ENTIRE RISK AS TO THE QUALITY AND
        PERFORMANCE IS WITH YOU. WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY
        KIND (EXPRESS, IMPLIED OR STATUTORY) WHICH INCLUDES BUT IS NOT LIMITED
        TO, ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY, FITNESS FOR
        A PARTICULAR USE OR PURPOSE, ACCURACY OF DATA, TITLE, NON-INFRINGEMENT
        OF INTELLECTUAL PROPERTY RIGHTS, AND WARRANTIES THAT MAY ARISE FROM
        COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OF TRADE.
        <br />
        <br />
        THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE SERVICES, THE APP OR THE
        SITE ARE FREE OF PROBLEMS. WITHOUT LIMITING THE GENERALITY OF THE
        FOREGOING, WE MAKE NO WARRANTY (I) THAT THE SERVICES, THE APP OR THE
        SITE WILL MEET YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED, TIMELY,
        SECURE, OR ERROR-FREE, OR THAT ANY SUCH ERRORS OR DEFECTS WILL BE
        CORRECTED, (II) THAT THE CONTENT, INCLUDING WITHOUT LIMITATION, THE
        SERVICES, APP AND SITE, ARE FREE FROM VIRUSES, HARMFUL COMPONENTS, OR
        MALICIOUS CONTENT, (III) THAT THE CONTENT, INCLUDING WITHOUT LIMITATION,
        THE SERVICES, APP AND SITE WILL MEET YOUR PERSONAL OR BUSINESS
        REQUIREMENTS, (IV) AS TO THE RESULTS THAT MAY BE OBTAINED OR AS TO THE
        ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE
        SERVICES, THE APP OR THE SITE, OR (V) THAT THE CONTENT, INCLUDING
        WITHOUT LIMITATION, THE SERVICES, APP AND SITE, WILL BE NON-INFRINGING.
        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
        THROUGH THE SERVICES, THE APP OR THE SITE OR FROM US OR OUR
        SUBSIDIARIES/OTHER AFFILIATED COMPANIES WILL CREATE ANY WARRANTY. WE
        DISCLAIM ALL EQUITABLE INDEMNITIES.
        <br />
        <br />
        YOU ACKNOWLEDGE AND AGREE THAT NEITHER LINT NOR ITS LICENSORS: (I) WILL
        BE CONSIDERED AN “EXPERT” UNDER THE SECURITIES ACT OF 1933, OR (II)
        REPRESENT OR WARRANT THAT THE SITE, APP AND SERVICES COMPLY WITH THE
        REQUIREMENTS OF THE FINANCIAL INDUSTRY REGULATORY AUTHORITY, THE
        SECURITIES AND EXCHANGE COMMISSION, OR ANY SIMILAR ORGANIZATION OR
        REGULATOR OR WITH THE SECURITIES LAWS OF ANY JURISDICTION. BY USING THE
        SERVICES, YOU ACKNOWLEDGE THAT THE INVESTMENT RESULTS YOU OBTAIN FROM
        FINANCIAL INSIGHTS PROVIDED BY LINT CANNOT BE GUARANTEED. ALL
        INVESTMENTS ENTAIL A RISK OF LOSS AND YOU MAY LOSE MONEY. YOU ALSO
        UNDERSTAND THAT ALL INVESTMENTS ARE SUBJECT TO VARIOUS ECONOMIC,
        POLITICAL, AND OTHER RISKS. WHILE THE SERVICES MAY ASSIST YOU IN HELPING
        YOU MANAGE YOUR FINANCES, YOU SHOULD CONSULT WITH A PROFESSIONAL
        FINANCIAL ADVISOR BEFORE MAKING INVESTMENT DECISIONS OR DECIDING ON
        SIGNIFICANT CHANGES TO YOUR PERSONAL FINANCIAL STRATEGY. ANY INFORMATION
        PROVIDED BY OR THROUGH THE SERVICES DOES NOT CONSTITUTE FINANCIAL,
        INVESTMENT, LEGAL, ACCOUNTING, TAX, OR OTHER ADVICE OF ANY KIND
        WHATSOEVER.
        <br />
        <br />
        YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SERVICES,
        THE APP AND THE SITE AND ANY RELATED SERVICES AND LINKED SITES. LINT
        DOES NOT WARRANT THAT THE CONTENT OR ANY FILES AVAILABLE FOR DOWNLOAD
        WILL BE FREE OF VIRUSES, WORMS, TROJAN HORSES OR OTHER DESTRUCTIVE
        PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING PROCEDURES SUFFICIENT
        TO SATISFY YOUR NEEDS FOR DATA BACK UP AND SECURITY.
        <br />
        <br />
        WE ARE NOT RESPONSIBLE FOR THE INFORMATION OR CONDUCT OF THIRD PARTIES
        OR FOR ANY DATA OBTAINED FROM THIRD-PARTY DATA SOURCES THAT ARE
        DISPLAYED OR REPORTED THROUGH THE SERVICES, AND YOU HEREBY RELEASE LINT
        FROM ANY AND ALL CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF
        OR IN ANY WAY OR TO ANY DEGREE CONNECTED WITH ANY CLAIM YOU MAY HAVE OR
        DEVELOP AGAINST ANY SUCH THIRD PARTIES.
        <br />
        <br />
        NEITHER LINT NOR ANY ENTITY WHOSE INFORMATION IS MADE AVAILABLE THROUGH
        THE SERVICES IS RESPONSIBLE FOR THE ACCURACY OF INFORMATION, DATA, OR
        CONTENT, INCLUDING, BUT NOT LIMITED TO, PRICES, QUOTES, EQUITY SALES,
        OTHER MARKET INFORMATION, AND INFORMATION DERIVED FROM ANY SUCH
        INFORMATION (“MARKET DATA”) THAT ARE DISPLAYED OR REPORTED THROUGH THE
        SERVICES.
        <br />
        <br />
        Lint does not guarantee or make any warranty of any kind, express or
        implied, regarding the timeliness, sequence, accuracy, completeness,
        usefulness, reliability, or content of Market Data. You agree to use
        Market Data and the Services at your own risk. You agree that neither
        Lint nor any entity whose information is made available through the
        Services will be held liable for any loss arising out of or relating to:
        (i) any inaccuracy, defect or omission in Market Data, (ii) any error or
        delay in the transmission of Market Data, or (iii) interruption in any
        Market Data service. You also acknowledge that any information you
        obtain from another Lint User comes from those individuals and not from
        Lint, and that Lint, to the fullest extent permitted by law, is not in
        any way responsible for any of the information these third parties may
        supply or for any statements, claims, or representations they may make.
        To the fullest extent permitted by law, Lint disclaims any such
        statements, claims or representations, and the same do not expand or
        otherwise modify these Terms. If you are dissatisfied with the Services,
        your sole and exclusive remedy is to stop accessing and using the
        Services.
        <br />
        <br />
        IF YOU ARE A RESIDENT OF CALIFORNIA, YOU EXPRESSLY WAIVE CALIFORNIA
        CIVIL CODE SECTION 1542 WHICH PROVIDES: A GENERAL RELEASE DOES NOT
        EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR EXPECT TO EXIST IN
        HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM
        MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.
        <br />
        <br />
        Some jurisdictions do not allow the exclusion of certain warranties, so
        some of the disclaimers above may not apply to you.
      </Card>
      <Headline size="small">Limitation and Liability</Headline>
      <Card class="p-b-2">
        LINT WILL NOT BE LIABLE TO YOU OR OTHERS FOR ANY DAMAGES RESULTING FROM
        YOUR USE OF THE SERVICES, APP OR SITE, OR YOUR DISPLAYING, COPYING, OR
        DOWNLOADING ANY MATERIALS TO OR FROM THE SERVICES, APP OR SITE. IN NO
        EVENT WILL LINT BE LIABLE TO YOU FOR ANY INDIRECT, EXTRAORDINARY,
        EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
        (INCLUDING LOSS OF DATA, REVENUE, PROFITS, OPPORTUNITIES, REPUTATION,
        USE, GOODWILL OR OTHER ECONOMIC ADVANTAGE, OR ANY OTHER INTANGIBLE
        LOSSES) HOWEVER ARISING, EVEN IF WE KNOW THERE IS A POSSIBILITY OF SUCH
        DAMAGE.
        <br />
        <br />
        LINT’S MAXIMUM AGGREGATE LIABILITY TO YOU IN CONNECTION WITH THESE
        TERMS, THE SERVICES, THE APP OR THE SITE, REGARDLESS OF THE FORM OF
        ACTION (WHETHER SUCH LIABILITY ARISES DUE TO NEGLIGENCE, BREACH OF
        CONTRACT, MISREPRESENTATION, OR FOR ANY OTHER REASON), WILL AT ALL TIMES
        BE LIMITED TO THE GREATER OF (I) THE AMOUNT PAID, IF ANY, BY YOU TO LINT
        IN CONNECTION WITH THESE TERMS IN THE TWELVE (12) MONTHS PRIOR TO THE
        ACTION GIVING RISE TO LIABILITY, OR (II) US $100.00.
        <br />
        <br />
        THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE AGREEMENT
        BETWEEN YOU AND LINT AND SHALL APPLY TO ALL CLAIMS OF LIABILITY, EVEN IF
        LINT HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF
        THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.
        <br />
        <br />
        Some jurisdictions may not allow the Limitation of Liability for certain
        damages and causes of action, so some of the disclaimers above may not
        apply to you.
      </Card>
      <Headline size="small">Local Laws and Export Control</Headline>
      <Card class="p-b-2">
        We control and operate the App and the Site from our headquarters in the
        United States of America and the entirety of the App and the Site may
        not be appropriate or available for use in other locations. If you use
        the App or the Site outside the United States of America, you are solely
        responsible for following applicable local laws.
        <br />
        <br />
        Lint and you shall comply with all relevant United States export control
        laws and regulations. Without limiting the foregoing: (a) you
        acknowledge that the App and the Site may be subject to the United
        States Export Administration Regulations; (b) you affirm that you are
        not, and you will not (i) permit Lint Users to access or use the App or
        the Site in a United States embargoed country; (ii) permit Lint Users on
        the U.S. Commerce Department’s Denied Persons, Entity, or Unverified
        Lists, or (iii) permit Lint Users on the U.S. Treasury Department’s list
        of Specially Designated Nationals and Consolidated Sanctions list; and ©
        you are responsible for complying with any local laws and regulations
        which may impact your right to import, export, access, or use the App or
        the Site.
      </Card>
      <Headline size="small">Force Majeure</Headline>
      <Card class="p-b-2">
        Lint will not be held liable for any delays or failure in performance of
        any part of the Service, the App or the Site from any cause beyond our
        control. This includes, but is not limited to, any delay or outage of
        any computer system, service, electronic device or internet service
        provider, and any acts of god, changes to law or regulations, embargoes,
        war, terrorist acts, riots, fires, earthquakes, nuclear accidents,
        pandemics, floods, strikes, power blackouts, volcanic action, unusually
        severe weather conditions, or acts of hackers.
      </Card>
      <Headline size="small">Survivability</Headline>
      <Card class="p-b-2">
        Even if these Terms are terminated, this section and the following
        sections will continue to apply: “Unauthorized Activities,” “Legal
        Compliance,” “Intellectual Property; Feedback,” “User Submissions,”
        “Access to the App, Site and Services,” “Indemnification,” “Disclaimers;
        Third Party Information,” “Limitation of Liability,” “Entire Agreement,”
        “Assignments,” “Severability,” “Interpretation,” “Waiver,” “Further
        Actions,” “Governing Law,” “Dispute Resolution,” “Mandatory Arbitration
        Agreement and Class Action Waiver,” and “Changes.”
      </Card>
      <Headline size="small">Entire Agreement</Headline>
      <Card class="p-b-2">
        These Terms, and any other agreements incorporated herein, including
        without limitation, the Privacy Policy, constitute the entire agreement
        between you and Lint pertaining to the subject matter hereof. Any and
        all other written or oral agreements existing between the Parties hereto
        regarding such subject matter are void and of no force or effect.
      </Card>
      <Headline size="small">Assignments</Headline>
      <Card class="p-b-2">
        You may not assign any of your rights under these Terms to anyone else.
        We may assign our rights to any other individual or entity at our
        discretion.
      </Card>
      <Headline size="small">Severability</Headline>
      <Card class="p-b-2">
        If it turns out that a section (or portion thereof) of these Terms is
        not enforceable, then that section will be removed or edited as little
        as required to make such section enforceable, and the rest of these
        Terms will still be valid.
      </Card>
      <Headline size="small">Interpretation</Headline>
      <Card class="p-b-2">
        The headers and sidebar text are provided only to make these Terms
        easier to read and understand. The fact that we wrote these Terms will
        not affect the way these Terms are interpreted.
      </Card>
      <Headline size="small">Waiver</Headline>
      <Card class="p-b-2">
        If we do not immediately take action on a violation of these Terms, such
        inaction does not constitute a waiver of our rights, we expressly do not
        give up any rights under these Terms, and we may still take action at
        any point that we choose.
      </Card>
      <Headline size="small">Further Actions</Headline>
      <Card class="p-b-2">
        You hereby agree to provide all documents and to take any actions
        necessary to meet your obligations under these Terms.
      </Card>
      <Headline size="small">Governing Law</Headline>
      <Card class="p-b-2">
        Any claim arising hereunder shall be construed in accordance with the
        substantive and procedural laws of the State of New York, without regard
        to principles of conflict of laws. Subject to the “Dispute Resolution”
        and “Mandatory Arbitration Agreement and Class Action Waiver” sections
        above, you agree that any Dispute shall be governed by the exclusive
        jurisdiction and venue of the state and Federal courts of New York
        County, New York. You further consent to service of process via email at
        the email address(es) provided by you, and you hereby waive any
        requirement under the Hague Convention or other judicial treaty
        requiring that legal process be translated into any language other than
        English.
      </Card>
      <Headline size="small">Dispute Resolution</Headline>
      <Card class="p-b-2">
        We know that things don’t always go as planned. In the event of any
        claim, action, controversy, or dispute relating to the App, the Site,
        Service, these Terms, or any other dispute between you and Lint
        (“Dispute”), you and Lint agree to use the following dispute resolution
        procedure before filing a claim or initiating arbitration:
        <li>
          The party asserting the Dispute shall provide a written notice
          describing the facts, circumstances, and any other relevant
          information to the other party (Notice).
        </li>
        <li>The notice shall be sent by registered or first class mail.</li>
        <li>
          The receiving party will be allowed 30 calendar days to respond to (or
          resolve) the Dispute.
        </li>
        <li>
          If the receiving party is Lint, the Notice shall be sent to the
          following address: 2315 E Spring St #1 Seattle, WA
        </li>
        <li>
          {" "}
          If the receiving party is the User, it shall be sent to one or both of
          the following addresses: (i) the last billing address you used, (ii)
          your shipping address (if available).
        </li>
        <br />
        <br />
        In the event that Lint does not have either of the above addresses for a
        User, Lint may (but is not obligated to) try and message you using any
        means of communication you provided and ask for your mailing address.
        The 30 days response time shall be counted from the day in which Lint
        has sent you the message asking for your mailing address.
      </Card>
      <Headline size="small">
        Mandatory arbitration agreement and class action waiver
      </Headline>
      <Card class="p-b-2">
        YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND
        LINT ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN
        A CLASS ACTION.
        <br />
        <br />
        In the event that an attempt to resolve a Dispute pursuant to the
        previous section is unsuccessful, you and Lint agree to resolve such
        Dispute by binding arbitration. This agreement to arbitrate Disputes
        includes all claims arising out of or relating to any aspect of the
        Site, the App, the Services or these Terms, whether based in contract,
        tort, statute, fraud, misrepresentation, or any other legal theory, and
        regardless of whether a claim arises during or after the termination of
        these Terms.
        <br />
        <br />
        Despite the provisions in this section, nothing in these Terms will be
        deemed to waive, preclude, or otherwise limit the right of either party
        to: (i) bring an individual action in small claims court; (ii) pursue an
        enforcement action through the applicable federal, state, or local
        agency if that action is available; (iii) seek injunctive relief in a
        court of law; or (iv) file suit in a court of law to address an
        intellectual property infringement claim.
        <br />
        <br />
        Any arbitration between you and Lint will be settled under the Federal
        Arbitration Act, and governed by the Commercial Dispute Resolution
        Procedures and the Supplementary Procedures for Consumer Related
        Disputes (collectively, “AAA Rules”) of the American Arbitration
        Association (“AAA”), as modified by these Terms, and will be
        administered by the AAA. The AAA Rules and filing forms are available
        online at www.adr.org, by calling the AAA at 1-800-778-7879, or by
        contacting Lint.
        <br />
        <br />
        A party who intends to seek arbitration must first send a written notice
        of the Dispute to the other party by certified U.S. Mail or by Federal
        Express (signature required) (“Notice”) to the addresses of the parties
        as set forth in the “Dispute Resolution” section. The Notice must: (a)
        describe the nature and basis of the claim or Dispute; and (b) set forth
        the specific relief sought (“Demand”). The parties will make good faith
        efforts to resolve the claim directly, but if the parties do not reach
        an agreement to do so within 30 days after the Notice is received, you
        or Lint may commence an arbitration proceeding. During the arbitration,
        the amount of any settlement offer made by you or Lint must not be
        disclosed to the arbitrator until after the arbitrator makes a final
        decision and award, if any. If the Dispute is finally resolved through
        arbitration in your favor, Lint will pay you the highest of the
        following: (i) the amount awarded by the arbitrator, if any; (ii) the
        last written settlement amount offered by Lint in settlement of the
        Dispute prior to the arbitrator’s award; or (iii) $100.
        <br />
        <br />
        Any arbitration hearing will take place at a location to be agreed upon
        in New York, New York, but if the claim is for $15,000 or less, you may
        choose whether the arbitration will be conducted: (i) solely on the
        basis of documents submitted to the arbitrator; (ii) through a
        non-appearance based telephone hearing; or (iii) by an in-person hearing
        as established by the AAA Rules in the county (or parish) of your
        billing address. If the arbitrator finds that either the substance of
        your claim or the relief sought in the Demand is frivolous or brought
        for an improper purpose (as measured by the standards set forth in
        Federal Rule of Civil Procedure 11(b)), then the payment of all fees
        will be governed by the AAA Rules. In that case, you agree to reimburse
        Lint for all monies previously disbursed by it that are otherwise your
        obligation to pay under the AAA Rules. Regardless of the manner in which
        the arbitration is conducted, the arbitrator must issue a reasoned
        written decision sufficient to explain the essential findings and
        conclusions on which the decision and award, if any, are based. The
        arbitrator may make rulings and resolve Disputes as to the payment and
        reimbursement of fees or expenses at any time during the proceeding and
        upon request from either party made within 14 days of the arbitrator’s
        ruling on the merits.
        <br />
        <br />
        YOU AND LINT AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
        YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER
        IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless
        both you and Lint agree otherwise, the arbitrator may not consolidate
        more than one person’s claims, and may not otherwise preside over any
        form of a representative or class proceeding.
        <br />
        <br />
        If Lint makes any future change to this arbitration provision, other
        than a change to our address for Notice, you may reject the change by
        sending us written notice within 30 days of the change to the Lint
        address for Notice, in which case your account with Lint (if you had
        one) will be immediately terminated and this arbitration provision, as
        in effect immediately prior to the changes you rejected will survive.
        <br />
        <br />
        If any portion of this binding arbitration provision is found to be
        unenforceable, then the entirety of this section will be null and void
        and, in that case, the parties agree that the exclusive jurisdiction and
        venue described in the “Governing Law” section will govern any action
        arising out of or related to these Terms. The arbitrator has exclusive
        authority to resolve any Dispute relating to the interpretation,
        applicability, or enforceability of this binding arbitration provision.\
      </Card>
      <Headline size="small">Changes</Headline>
      <Card class="p-b-2">
        We may amend our Terms at any time by publishing revised Terms on our
        App or the Site and/or by sending an email to the address you gave us.
        These Terms are current as of the date first set forth above. If any
        changes are made to these Terms, we will post them on this page, so
        please be sure to check back periodically. If you continue to use the
        App, the Site or any other Services after those changes are in effect,
        you agree to the revised Terms. Therefore, it is important for you to
        periodically review our Terms to make sure you still agree to them. If
        you object to any such modifications, your sole recourse will be to
        cease using the Services, the App and the Site. Lint reserves the right
        to change any and all features of our Services, the App and the Site, at
        any time, without notice.
      </Card>
      <Headline size="small">Contact Us</Headline>
      <Card class="p-b-2">
        If you have any questions about these Terms or otherwise need to contact
        us for any reason, you can reach us at dolsenj@umich.edu.
      </Card>
    </Fragment>
  );
};
