import {action, makeObservable, observable, runInAction } from 'mobx';
import { InMemoryDataSource, TableState } from '@servicetitan/table';
import { FormEvent } from 'react';
import moment from 'moment';
import { TransactionModel, Transactions } from '../../transaction/api/transactions.api';
import { Categories, CategoryModel, CategoryTransaction } from '../api/categories.api';
export class CategoryTransactionsStore {
    @observable category: CategoryModel | undefined = undefined;
    @observable tableState: TableState<any, number>;
    date = new Date();
    @observable startDate: Date | undefined = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    @observable endDate: Date | undefined = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 1);
    @observable transactions: CategoryTransaction[] = [];
    @observable page: number = 0
    @observable loading: boolean = true;
    @observable showDeletedToggle: boolean = false;
    @observable showTransactions: CategoryTransaction[] = [];
    categoryApi: Categories = new Categories();
    transactionApi: Transactions = new Transactions();

    constructor() {
        makeObservable(this);
        this.tableState = new TableState<any, number, never, never>({
            pageSize: 10,
            dataSource: this.getDataSource(),
        });
    }

    @action
    getCategory = async (id: number) => {
        const category = await this.categoryApi.getCategory(id);
        runInAction( () => {
            if (category.data) {
                this.category = category.data;
                this.getCategoryTransactions(id);
            }
        })
    };

    @action
    getCategoryTransactions = async (id: number) => {
        const categoryTransactions = await this.categoryApi.getCategoryTransactions(id, this.startDate?.toISOString().split('T')[0], this.endDate?.toISOString().split('T')[0])
        runInAction(() => {
            if (categoryTransactions.data) {
                this.transactions = categoryTransactions.data;
                this.setActiveTransactions();
                this.loading = false;
            }
        })
    }

    @action
    setStartDate = (date: Date | undefined) => {
        this.startDate = date;
    };
    
    @action
    setEndDate = (date: Date | undefined) => {
        this.endDate = date;
    };

    @action showDeleted = () => {
        this.showTransactions = this.transactions;
    };

    @action showActive = () => {
        this.showTransactions = this.transactions.filter(item => { return item.is_active == true; });
    };

    @action
    toggleShowDeleted = () => {
        this.showDeletedToggle = !this.showDeletedToggle;
        this.setActiveTransactions();
    }

    @action
    deleteTransaction = async (transaction: TransactionModel) => {
        transaction.is_active = false;
        transaction.transaction_date = transaction.transaction_date?.[0];
        transaction.date_changed = transaction.date_changed?.[0];
        await this.transactionApi.updateTransaction(transaction)
        runInAction( () => {
            this.getCategoryTransactions(this.category?.id || 0);
        })
    };

    @action
    restoreTransaction = async (transaction: TransactionModel) => {
        transaction.is_active = true;
        transaction.transaction_date = transaction.transaction_date?.[0];
        transaction.date_changed = transaction.date_changed?.[0];
        await this.transactionApi.updateTransaction(transaction)
        runInAction( () => {
            this.getCategoryTransactions(this.category?.id || 0);
        })
    };
    

    setActiveTransactions = () => {
        this.showDeletedToggle ?  this.showDeleted() : this.showActive(); 
        this.tableState.setDataSource(this.getDataSource(), { reset: true });
    }

    getDataSource = () => {
        return new InMemoryDataSource(this.showTransactions, this.idSelector, {});
    }

    idSelector = (row: any) => {
        return row.Id;
    }
}