import axiosConfig from "../axiosConfig";
import authHeader from './auth-header';

class CloseMonthService {
  postCloseMonth() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('close-month/' + id, "", { headers: authHeader() });
  };
}

export default new CloseMonthService();