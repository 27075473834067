import {action, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { FormEvent } from 'react';
import { AccountModel } from '../../accounts/api/accounts.api';
import { CategoryModel } from '../../categories/api/categories.api';
import { TransactionModel, Transactions } from '../api/transactions.api';


export class TransactionStore {
    @observable transaction: TransactionModel | undefined = undefined;
    @observable newTransactionAccountId: number | undefined = 0;
    @observable newTransactionCategoryId: number | undefined = 0;
    @observable newTransactionTransferCategoryId: number | undefined = 0;
    @observable newTransactionAmount: string = '';
    @observable newTransactionMessage: string | undefined = '';
    @observable newTransactionDate: Date | undefined = undefined;
    @observable newBudgetedDate: Date | undefined = undefined;
    @observable newTransactionTypeId: number | undefined = 0;
    @observable accounts: AccountModel[] = [];
    @observable categories: CategoryModel[] = [];
    @observable accountsDropdown: any = [];
    @observable categoriesDropdown: any = [];
    @observable previousAccountId: number = 0;
    @observable previousCategoryId: number = 0;
    transactionApi: Transactions = new Transactions();

    constructor() {
        makeObservable(this);
    }

    @action
    getTransaction = async (id: number, account_id: number, category_id: number) => {
        await this.getAccounts();
        await this.getCategories();
        this.newTransactionAccountId = account_id;
        this.newTransactionCategoryId = category_id;
        if (id <= 0) return;
        const transaction = await this.transactionApi.getTransaction(id);
        runInAction( () => {
            if (transaction.data) {
                this.transaction = transaction.data;
                this.newTransactionAccountId = this.transaction?.account_id;
                this.newTransactionCategoryId = this.transaction?.category_id;
                this.newTransactionTransferCategoryId = this.transaction?.transfer_category_id;
                this.newTransactionAmount = this.transaction?.amount.toString() || '';
                this.newTransactionMessage = this.transaction?.message;
                this.newTransactionDate = this.transaction?.transaction_date ? new Date(this.transaction?.transaction_date?.[0]) : undefined;
                this.newBudgetedDate = this.transaction?.date_changed ? new Date(this.transaction?.date_changed?.[0]) : undefined;
                this.newTransactionTypeId = this.transaction?.transaction_type_id || 0;
            }
        })
    };

    @action
    setNewTransactionAccountId = (data: any) => {
        this.newTransactionAccountId = data?.value || 0;
    };

    @action
    setNewTransactionCategoryId = (data: any) => {
        this.newTransactionCategoryId = data?.value || 0;
    };

    @action
    setNewTransactionTransferCategoryId = (data: any) => {
        this.newTransactionTransferCategoryId = data?.value || 0;
    };

    @action
    setNewTransactionMessage = (event: FormEvent<HTMLInputElement>) => {
        this.newTransactionMessage = event.currentTarget.value;
    };

    @action
    setNewTransactionAmount = (event: FormEvent<HTMLInputElement>) => {
        this.newTransactionAmount = event.currentTarget.value.replace(/[^0-9\.-]+/g, '');
    };

    @action
    setTransactionDate = (date: Date | undefined) => {
        this.newTransactionDate = date;
    };

    @action
    setBudgetedDateDate = (date: Date | undefined) => {
        this.newBudgetedDate = date;
    };

    @action
    getAccounts = async () => {
        const accounts = await this.transactionApi.getAccounts();
        runInAction( () => {
            if (accounts.data) {
                this.accounts = accounts.data;
                this.accountsDropdown = [];
                accounts.data.forEach((item: AccountModel) => {
                    if (item.is_active) this.accountsDropdown.push({text: item.account_name, value: item.id})
                });
            }
        })
    };

    @action
    getCategories = async () => {
        const categories = await this.transactionApi.getCategories();
        runInAction( () => {
            if (categories.data) {
                this.categories = categories.data;
                this.categoriesDropdown = [];
                categories.data.forEach((item: CategoryModel) => {
                    if (item.is_active) this.categoriesDropdown.push({text: item.category_name, value: item.id})
                });
            }
        })
    };

    @action
    save = async () => {
        const transactionModel: TransactionModel = {
            id: this.transaction?.id || 0,
            account_id: this.newTransactionAccountId || 0,
            category_id: this.newTransactionCategoryId || 0,
            message: this.newTransactionMessage,
            transfer_category_id: this.newTransactionTransferCategoryId,
            transaction_date: this.newTransactionDate?.toISOString().split('T')[0],
            is_active: this.transaction?.is_active,
            amount: Number(this.newTransactionAmount),
            plaid_transaction_id: undefined,
            date_changed: this.newBudgetedDate?.toISOString().split('T')[0],
            transaction_type_id: ((this.newTransactionTypeId || 0) > 0) ? (this.newTransactionTypeId || 0) : (Number(this.newTransactionAmount) > 0) ? 1 : 2
        }
        await this.transactionApi.updateTransaction(transactionModel)
        runInAction(() => {
            this.previousAccountId > 0 ? window.open("/accounts/" + this.previousAccountId, "_self") : window.open("/categories/" + this.previousCategoryId, "_self");
        })
    };
}