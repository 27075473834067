import axiosConfig from "../axiosConfig";
import authHeader from './auth-header';

class PlaidService {

  postItem() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('plaid_item/set_access_token/' + id, {}, { headers: authHeader() });
  };

  generateToken() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('plaid_item/create_link_token/' + id, {}, {headers: authHeader()});
  };

  getLink() {
    const token = localStorage.getItem('link_token');
    return axiosConfig.get('plaid_item/get_link/' + token, { headers: authHeader() });
  };

  exchangePublicAccess(publicKey, institutionId, institutionName) {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('plaid_item/exchange_public_access/' + id, {"public_key": publicKey, "institution_id": institutionId, "institution_name": institutionName}, { headers: authHeader() });
  }

  getItems() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('plaid_item/get_items/' + id, { headers: authHeader() });
  };

  getAllItems() {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.get('plaid_item/get_all_items/' + id, { headers: authHeader() });
  };

  resetAccessToken(accessToken) {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('plaid_item/reset_access_token/' + id, {"access_token": accessToken}, { headers: authHeader() });
  };

  updateAccessToken(accessToken) {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('plaid_item/update_access_token/' + id, {"access_token": accessToken}, { headers: authHeader() });
  };

  updateItem(itemId, active) {
    return axiosConfig.post('plaid_item/update_item/' + itemId, { is_active: active }, { headers: authHeader() });
  };

  getItemTransactions(accessToken) {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('plaid_item/get_item_transactions/' + id, {"access_token": accessToken}, { headers: authHeader() });
  };

  getInstitutionImage(institutionId) {
    const id = JSON.parse(localStorage.getItem('user'))[0].id;
    return axiosConfig.post('plaid_item/get_institution_image/' + id, {"institution_id": institutionId}, { headers: authHeader() });
  };

}

export default new PlaidService();