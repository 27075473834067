import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PlaidService from "../services/plaid-service";
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
const initialState = {
  links: [],
  retries: 0,
  status: 'idle',
  error: null,
  publicToken: '',
  linkSuccess: false,
  isItemAccess: true,
  linkToken: "", // Don't set to null or error message will show up briefly when site loads
  accessToken: null,
  itemId: null,
  isError: false,
  backend: true,
  products: ["transactions"],
  linkTokenError: {
    error_type: "",
    error_code: "",
    error_message: ""
  },
  items: [],
  itemTransactions: [],
  institutionImage: {}
}

export const addLink = createAsyncThunk(
    'link',
    // The payload creator receives the partial `{transferAccountid, content}` object
    async initialLink => {
      const response = await PlaidService.postItem()
      // The response includes the complete post object, including unique ID
      return response.data
    }
  );

  export const generateLinkToken = createAsyncThunk(
    'generateLinkToken',
    // The payload creator receives the partial `{transferAccountid, content}` object
    async initialLinkToken => {
      const response = await PlaidService.generateToken()
      // The response includes the complete post object, including unique ID
      return response.data
    }
  );

  export const fetchLink = createAsyncThunk('getLink', async () => {
    const response = await PlaidService.getLink();
    return response.data
  });

  export const fetchItems = createAsyncThunk('getItems', async () => {
    const response = await PlaidService.getItems();
    return response.data
  });

  export const exchangePublicAccess = createAsyncThunk(
    'exchangePublicAccess',
    // The payload creator receives the partial `{transferAccountid, content}` object
    /**  @param arg { publicToken: string, metaData: PlaidLinkOnSuccessMetadata } */
    async ({ publicToken, metaData }) => {

      const response = await PlaidService.exchangePublicAccess(publicToken, metaData.institution.institution_id, metaData.institution.name)
      // The response includes the complete post object, including unique ID
      return response.data
    }
  );

  export const fetchAllItems = createAsyncThunk('getAllItems', async () => {
    const response = await PlaidService.getAllItems();
    return response.data
  });

  export const resetAccessToken = createAsyncThunk(
    'resetAccessToken',
    // The payload creator receives the partial `{transferAccountid, content}` object
    /**  @param arg { accessToken: string } */
    async ({ accessToken }) => {
      const response = await PlaidService.resetAccessToken(accessToken)
      return response.data
    }
  );

  export const updateAccessToken = createAsyncThunk(
    'updateAccessToken',
    // The payload creator receives the partial `{transferAccountid, content}` object
    /**  @param arg { accessToken: string } */
    async ({ accessToken }) => {
      const response = await PlaidService.updateAccessToken(accessToken)
      return response.data
    }
  );

  export const updateItem = createAsyncThunk(
    'updateItem',
    // The payload creator receives the partial `{transferAccountid, content}` object
    /**  @param arg { itemId: number, active: boolean } */
    async ({ itemId, active }) => {
      const response = await PlaidService.updateItem(itemId, active)
      return response.data
    }
  );

  export const fetchItemTransactions = createAsyncThunk(
    'fetchItemTransactions',
    // The payload creator receives the partial `{transferAccountid, content}` object
    /**  @param arg { accessToken: string } */
    async ({ accessToken }) => {
      const response = await PlaidService.getItemTransactions(accessToken)
      return response.data
    }
  );

  export const fetchInstitutionImage = createAsyncThunk(
    'fetchInstitutionImage',
    // The payload creator receives the partial `{transferAccountid, content}` object
    /**  @param arg { institutionId: string } */
    async ({ institutionId }) => {
      const response = await PlaidService.getInstitutionImage(institutionId)
      return response.data.institution
    }
  );

const plaidSlice = createSlice({
    name: 'plaid',
    initialState,
    reducers: {
      linksAdded: {
        reducer(state, action) {
          state.links.push(action.payload)
        },
        prepare(title, content, userId) {
          // omit prepare logic
        }
      },
    },
    extraReducers: {
        [addLink.pending]: (state, action) => {
            state.status = 'loading'
        },
        [addLink.fulfilled]: (state, action) => {
            state.status = 'added'
        },
        [addLink.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [resetAccessToken.pending]: (state, action) => {
            state.status = 'loading'
        },
        [resetAccessToken.fulfilled]: (state, action) => {
            state.status = 'added'
        },
        [resetAccessToken.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [updateAccessToken.pending]: (state, action) => {
            state.status = 'loading'
        },
        [updateAccessToken.fulfilled]: (state, action) => {
            state.linkToken = action.payload.link_token
            state.status = 'update link'
        },
        [updateAccessToken.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [updateItem.pending]: (state, action) => {
            state.status = 'loading'
        },
        [updateItem.fulfilled]: (state, action) => {
            state.status = 'done'
        },
        [updateItem.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchItemTransactions.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchItemTransactions.fulfilled]: (state, action) => {
            state.itemTransactions = action.payload.transactions
            state.status = 'fetched'
        },
        [fetchItemTransactions.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchInstitutionImage.pending]: (state, action) => {
          state.status = 'loading'
        },
        [fetchInstitutionImage.fulfilled]: (state, action) => {
            state.institutionImage = action.payload
            state.status = 'fetched'
        },
        [fetchInstitutionImage.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [generateLinkToken.pending]: (state, action) => {
            state.status = 'loading'
        },
        [generateLinkToken.fulfilled]: (state, action) => {
            state.status = 'generated'
            state.linkToken = action.payload.link_token
            localStorage.setItem("link_token", action.payload.link_token); //to use later for Oauth
        },
        [generateLinkToken.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchLink.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchLink.fulfilled]: (state, action) => {
            state.status = 'fetched'
        },
        [fetchLink.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchItems.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchItems.fulfilled]: (state, action) => {
            state.status = 'fetched'
        },
        [fetchItems.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchAllItems.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchAllItems.fulfilled]: (state, action) => {
            state.status = 'fetched'
            state.items = action.payload
        },
        [fetchAllItems.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [exchangePublicAccess.pending]: (state, action) => {
            state.status = 'loading'
        },
        [exchangePublicAccess.fulfilled]: (state, action) => {
            state.status = 'exchanged'
            state.retries = 0
        },
        [exchangePublicAccess.rejected]: (state, action) => {
            state.error = action.error.message
            state.retries += 1;
            state.status = 'retry'
            if (state.retries > 5){
              state.status = 'plaid api down'
            }
            state.publicToken = action.meta.arg.publicToken;

        }
    }
  })
  
  export const { linksAdded } = plaidSlice.actions
  
  export default plaidSlice.reducer
  
  export const selectLinkToken = state => state.plaid.linkToken;

  export const selectAllItems = state => state.plaid.items;

  export const selectPlaidStatus = state => state.plaid.status;

  export const selectPlaidPublicToken = state => state.plaid.publicToken;

  export const selectItemTransactions = state => state.plaid.itemTransactions;

  export const selectInstitutionImage = state => state.plaid.institutionImage;

  //export const selectAllMonthlyAccounts = state => state.home.monthlyAccounts
  